import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { OnpremiseServer } from '../api/models/onpremise-server';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatRadioChange } from '@angular/material/radio';
import moment from 'moment';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-onpremiseserver',
  templateUrl: './onpremiseserver.component.html',
  styleUrls: ['./onpremiseserver.component.css']
})
export class OnpremiseserverComponent implements OnInit, AfterViewInit {

  ranges: any = {
    'Last 10 Minutes': [moment().subtract(10, 'minutes'), moment()],
    'Last 30 Minutes': [moment().subtract(30, 'minutes'), moment()],
    'Last Hour': [moment().subtract(60, 'minutes'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  }

  dateRange = new FormControl({
    endDate: moment(),
    startDate: moment().subtract(6, 'days')
  });

  onpremiseserver$: Observable<OnpremiseServer>;
  current_id = ''

  system_cpu = [];
  system_ram = [];
  system_net_inbound = [];
  system_net_outbound = [];
  system_swap = [];
  status = [];
  aggregate_queue = [];
  fingerprint_queue = [];
  upload_queue = [];

  constructor(private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {
    this.onpremiseserver$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.retrieve.getOnpremiseServer({ id: params.get('id') }))
    );
    
    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
       this.dateRange.setValue({
        startDate: params['startDate'] ? moment(params['startDate']) : moment().subtract(30, 'minutes'),
        endDate: params['endDate'] ? moment(params['endDate']) : moment(),
      }, { emitEvent: false });
      this.retrieveMetrics();
    });    
  }

  ngAfterViewInit() {
    this.dateRange.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.route.paramMap.subscribe(
        paramMap => {
          const id_server = paramMap.get('id')
          this.router.navigate(['onpremiseserver/' + id_server], {
            queryParams: {
              startDate: value.startDate,
              endDate: value.endDate
            },
            queryParamsHandling: 'merge'
          });
        }
      )
    });
  }

  retrieveMetrics(): void {
    this.route.paramMap.subscribe(
      paramMap => {
        const id_server = paramMap.get('id')
        this.retrieve.getOnpremiseServerMetrics({
          id: id_server,
          dateFrom: this.dateRange.value.startDate.toISOString(),
          dateTo: this.dateRange.value.endDate.toISOString()
        }).subscribe(
          metrics => {
            metrics.forEach(metric => {
              metric.series.forEach((item: any) => {
                item.name = new Date(moment.utc(item.name).local().toISOString());
              });
              metric.name = metric.name.toLowerCase()
              if (metric.name == 'system_cpu') {
                this.system_cpu = [metric];
              } else if (metric.name == 'system_ram') {
                this.system_ram = [metric]
              } else if (metric.name == 'system_net_inbound') {
                this.system_net_inbound = [metric]
              } else if (metric.name == 'system_net_outbound') {
                this.system_net_outbound = [metric]
              } else if (metric.name == 'system_swap') {
                this.system_swap = [metric]
              } else if (metric.name == 'status') {
                this.status = [metric]
              } else if (metric.name.includes('aggregate_queue')) {
                this.aggregate_queue = [metric]
              }else if (metric.name.includes('fingerprint_queue')) {
                this.fingerprint_queue = [metric]
              }else if (metric.name.includes('upload_queue')) {
                this.upload_queue = [metric]
              }
            })
          }
        )
      }
    )
  }
}
