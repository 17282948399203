import { AfterViewInit, Component, OnInit, ViewChild, } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ContentsListDataSource } from '../contents-list/contents-list-datasource';
import { RetrieveService } from '../api/services/retrieve.service';
import { OperateService } from '../api/services/operate.service';
import { Content } from '../api/models/content';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MatchSettingsComponent } from '../match-settings/match-settings.component';
import { MatchPolicy } from '../api/models/match-policy';
import { Type } from '../api/models/type'
import { OnpremiseServer } from '../api/models/onpremise-server'
import { SearchParams } from '../contents-list/contents-list.component';
import { AudioFeatProperty } from '../api/models/audio-feat-property'
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';


interface TaskStatusView {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-channel',
  templateUrl: './channel.component.html',
  styleUrls: ['./channel.component.css']
})
export class ChannelComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<Content>;

  fingerprintVersions = environment.fingerprintVersions;
  defaultFingerprintVersion=environment.defaultFingerprintVersion;

  channel: string;

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';

  /** query parameters */
  searchParams: SearchParams = {
    type: Type.Linear,
    version: this.defaultFingerprintVersion,
  };

  /** Table data source */
  dataSource: ContentsListDataSource;

  /* Filters */
  idFilter = new FormControl();
  idServerFilter = new FormControl();
  udpPortFilter = new FormControl();

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['id', 'length', 'download', 'audiofeat', 'indexing', 'audiomatch', 'inserted', 'onair'];

  /* Datepickers helpers */
  insertedFrom = new FormControl();
  insertedTo = new FormControl();
  onairFrom = new FormControl();
  onairTo = new FormControl();

  /* Task status */
  taskStatuses: TaskStatusView[] = [
    { value: 'none', viewValue: 'Empty' },
    { value: 'requested', viewValue: 'Requested' },
    { value: 'completed', viewValue: 'Completed' },
    { value: 'error', viewValue: 'Error' }
  ];

  downloadStatus = new FormControl();
  audiofeatStatus = new FormControl();
  indexingStatus = new FormControl();
  audiomatchStatus = new FormControl();

  fingerprintVersionFilter = new FormControl();

  includeLinearPolicy = new FormControl();
  includeOndemandPolicy = new FormControl();
  includeListOfReferencesPolicy = new FormControl();

  servers$: Observable<OnpremiseServer[]>;

  constructor(
    private retrieve: RetrieveService,
    private operate: OperateService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.servers$ = this.retrieve.getOnpremiseServersList({limit: -1, offset: 0});

    this.channel = this.route.snapshot.paramMap.get("channel");

    this.dataSource = new ContentsListDataSource(this.retrieve);

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.downloadStatus.setValue(params['download'], { emitEvent: false });
      this.audiofeatStatus.setValue(params['audiofeat'], { emitEvent: false });
      this.indexingStatus.setValue(params['indexing'], { emitEvent: false });
      this.audiomatchStatus.setValue(params['audiomatch'], { emitEvent: false });
      this.idFilter.setValue(params['id'], { emitEvent: false });
      this.idServerFilter.setValue(params['id_server'], { emitEvent: false });
      this.udpPortFilter.setValue(params['udp_port'], { emitEvent: false });
      this.insertedFrom.setValue(params['insertedFrom'] ? moment(params['insertedFrom']) : null, { emitEvent: false });
      this.insertedTo.setValue(params['insertedTo'] ? moment(params['insertedTo']) : null, { emitEvent: false });
      this.onairFrom.setValue(params['onairFrom'] ? moment(params['onairFrom']) : null, { emitEvent: false });
      this.onairTo.setValue(params['onairTo'] ? moment(params['onairTo']) : null, { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'desc';
      this.sortActive = params['sortActive'] || 'inserted';
      this.fingerprintVersionFilter.setValue(params['version'] ? parseInt(params['version']) : this.defaultFingerprintVersion, { emitEvent: false })
      this.loadContentsPage();
    });

  }
  ngAfterViewInit() {

    this.fingerprintVersionFilter.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          version: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.idFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          id: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.idServerFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          id_server: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.udpPortFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          udp_port: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.downloadStatus.valueChanges
      .subscribe(value => {
        this.router.navigate(['channel/',this.channel], {
          queryParams: {
            download: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });

    this.audiomatchStatus.valueChanges
      .subscribe(value => {
        this.router.navigate(['channel/',this.channel], {
          queryParams: {
            audiomatch: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });

    this.audiofeatStatus.valueChanges
      .subscribe(value => {
        this.router.navigate(['channel/',this.channel], {
          queryParams: {
            audiofeat: value,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });
    
    this.indexingStatus.valueChanges
    .subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          indexing: value,
          pageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.sort.sortChange
      .subscribe(() => {
        this.router.navigate(['channel/',this.channel], {
          queryParams: {
            sortDirection: this.sort.direction,
            sortActive: this.sort.active,
            pageIndex: 0,
          },
          queryParamsHandling: 'merge'
        });
      });

    this.paginator.page
      .subscribe(() => {
        this.router.navigate(['channel/',this.channel], {
          queryParams: {
            pageIndex: this.paginator.pageIndex,
            pageSize: this.paginator.pageSize
          },
          queryParamsHandling: 'merge'
        });
      });

    /* Dates */
    this.insertedFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          pageIndex: 0,
          insertedFrom: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.insertedTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          pageIndex: 0,
          insertedTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.onairFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          pageIndex: 0,
          onairFrom: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.onairTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['channel/',this.channel], {
        queryParams: {
          pageIndex: 0,
          onairTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

  }

  loadContentsPage(): void {
    /**
     * Load a page of contents based on current parameters
     */

    /* Channel */
    this.searchParams['channel'] = this.channel;

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Sorting */
    this.searchParams['order'] = this.sortDirection;
    this.searchParams['sort'] = this.sortActive as "id" | "editor" | "published" | "onair" | "inserted";


    /* Filters */
    this.searchParams['id'] = this.idFilter.value ? this.idFilter.value: null;
    this.searchParams['id_server'] = this.idServerFilter.value ? this.idServerFilter.value: null;
    this.searchParams['udp_port'] = this.udpPortFilter.value ? this.udpPortFilter.value: null;
    this.searchParams['version'] = this.fingerprintVersionFilter.value;

    /* Dates */
    this.searchParams['insertedFrom'] = this.insertedFrom.value ? this.insertedFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['insertedTo'] = this.insertedTo.value ? this.insertedTo.value.format('YYYY-MM-DD') : null;
    this.searchParams['onairFrom'] = this.onairFrom.value ? this.onairFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['onairTo'] = this.onairTo.value ? this.onairTo.value.format('YYYY-MM-DD') : null;

    /* Tasks */
    this.searchParams['download'] = this.downloadStatus.value;
    this.searchParams['audiofeat'] = this.audiofeatStatus.value;
    this.searchParams['indexing'] = this.indexingStatus.value;
    this.searchParams['audiomatch'] = this.audiomatchStatus.value;


    /* Run */
    this.dataSource.loadContents(this.searchParams);
  }

  queueDownload(amid: string) {
    this.operate.queueDownload({ amid: amid })
      .pipe(finalize(() => {
        this.dataSource.updateContent(amid);
      }))
      .subscribe(
        () => {
          this.snackBar.open("Download requested for: " + amid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting download for: ${amid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  queueAudiofeat(amid: string, version: number) {
    this.operate.queueAudiofeat({ amid: amid, version: version })
      .pipe(finalize(() => {
        this.dataSource.updateContent(amid);
      }))
      .subscribe(
        () => {
          this.snackBar.open("Feature extraction requested for: " + amid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting feature extraction for: ${amid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  queueIndexing(amid: string, version: number) {
    this.operate.queueIndexing({ amid: amid, version:version, delete_fingerprint: false})
      .pipe(finalize(() => {
        this.dataSource.updateContent(amid);
      }))
      .subscribe(
        () => {
          this.snackBar.open("Indexing requested for: " + amid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting indexing for: ${amid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  queueAudiomatch(amid: string, version: number, body: any) {
    this.operate.queueAudiomatch({ amid: amid, version: version, body: body })
      .pipe(finalize(() => {
        this.dataSource.updateContent(amid);
      }))
      .subscribe(
        () => {
          this.snackBar.open("Matching requested for: " + amid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting matching for: ${amid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  filterByEditor(editor: string): void {
    this.router.navigate(['contents/'], {
      queryParams: {
        editor: editor,
        pageIndex: 0
      },
      queryParamsHandling: 'merge'
    });
  }

  openAudiomatchSettings(amid: string, editor: string,
    inserted: string, version: number): void {

    const insertedDate = moment(inserted);

    const dialogData = {
      amid: amid,
      editor: editor,
      channel: this.channel,
      insertedFrom: moment(insertedDate).subtract(7, 'd').format("YYYY-MM-DD"),
      insertedTo: moment(insertedDate).add(7, 'd').format("YYYY-MM-DD"),
      publishedFrom: moment(insertedDate).subtract(7, 'd').format("YYYY-MM-DD"),
      publishedTo: moment(insertedDate).add(7, 'd').format("YYYY-MM-DD"),
      onairFrom: moment(insertedDate).subtract(7, 'd').format("YYYY-MM-DD"),
      onairTo: moment(insertedDate).add(7, 'd').format("YYYY-MM-DD"),
      includeLinear: true,
      includeOndemand: true,
      version:version,
    }

    const dialogRef = this.dialog.open(MatchSettingsComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(result => {
      if (!(result == null || result == '')) {
        let params: MatchPolicy = { includeLinear: result.includeLinear, includeOndemand: result.includeOndemand };
        if (result.insertedFrom) {
          params.insertedFrom = moment(result.insertedFrom).format('YYYY-MM-DD');
        }
        if (result.insertedTo) {
          params.insertedTo = moment(result.insertedTo).format('YYYY-MM-DD');
        }
        if (params.includeOndemand && result.publishedFrom) {
          params.publishedFrom = moment(result.publishedFrom).format('YYYY-MM-DD');
        }
        if (params.includeOndemand && result.publishedTo) {
          params.publishedTo = moment(result.publishedTo).format('YYYY-MM-DD');
        }
        if (params.includeLinear && result.onairFrom) {
          params.onairFrom = moment(result.onairFrom).format('YYYY-MM-DD');
        }
        if (params.includeLinear && result.onairTo) {
          params.onairTo = moment(result.onairTo).format('YYYY-MM-DD');
        }
        if (result.editor) {
          params.editor = result.editor;
        }
        if (result.channel) {
          params.channel = result.channel;
        }

        this.queueAudiomatch(amid, result.version, params);
      }
    });

  }

  getIndexingTask(audioFeatProperty: AudioFeatProperty){
    
    let indexing_task

    if (audioFeatProperty.indexing_status == 'completed'){
      indexing_task = {
        'status': audioFeatProperty.indexing_status,
        'elapsed': audioFeatProperty.indexing_elapsed,
        'lastUpdate': audioFeatProperty.indexing_lastUpdate
      };
    }else if (audioFeatProperty.indexing_status == 'requested'){
      indexing_task = {
        'status': audioFeatProperty.indexing_status,
        'lastUpdate': audioFeatProperty.indexing_lastUpdate
      };
    }else if (audioFeatProperty.indexing_status == 'error'){
      indexing_task = {
        'status': audioFeatProperty.indexing_status,
        'lastUpdate': audioFeatProperty.indexing_lastUpdate,
        'error': audioFeatProperty['indexing_error']
      };
    } else
    {
      indexing_task = {
        'status': 'none'
      };
    }
    return indexing_task;
  }

}
