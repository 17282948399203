export const environment = {
  production: true,
  fingerprintVersions: [1,2, 3],
  defaultFingerprintVersion: 3,
  apiEndpoint: 'https://rz07nlv5d1.execute-api.eu-south-1.amazonaws.com/prod',
  contentBucket: 'audiomatch-backend-prod-contentbucket-18oy2ks380qm',
  accessKeyId: 'AKIA5FBSHIMW2EVHY3SV',
  secretAccessKey: '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',
  region: 'eu-south-1',
};
