import { Component, ChangeDetectionStrategy, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { CalendarEvent, CalendarEventTitleFormatter, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import { Observable, of, zip } from 'rxjs';
import { RetrieveService } from '../api/services/retrieve.service';
import moment from 'moment';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { bool } from 'aws-sdk/clients/signer';
import { ShowSchedule } from '../api/models/show-schedule';
import { Type } from '../api/models/type';


import domtoimage from 'dom-to-image-improved';
import { ChannelBundle } from '../api/models/channel-bundle';
import { Content } from '../api/models/content';

interface Show {
  title: string;
  start_date: string;
  end_date: string;
}

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};

@Component({
  selector: 'app-view-showschedule',
  templateUrl: './view-showschedule.component.html',
  styleUrls: ['./view-showschedule.component.css'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
  styles: [
    `
      .my-custom-class span {
        color: #ff3d7f !important;
        border-color: $primary;
      }
    `,
  ],
})
export class ViewShowscheduleComponent implements OnInit, AfterViewInit {

  view: CalendarView = CalendarView.Week;

  viewDate: Date = new Date();

  events$: Observable<CalendarEvent[]>;

  activeDayIsOpen: boolean = false;

  exportDisabled: boolean = false;

  ranges: any = {
    'Last 3 days': [moment().subtract(3, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  }


  hourSegments = "12";
  showscheduleDateRange = new FormControl({
    endDate: moment(),
    startDate: moment().subtract(24, 'hours')
  });
  hourFrom = new FormControl();
  hourTo = new FormControl();
  minuteFrom = new FormControl();
  minuteTo = new FormControl();
  channel = new FormControl();
  onlyFull = new FormControl();
  showInterruption = new FormControl();
  showAnomalies = new FormControl();
  daysInWeek = 7;
  dayStartHour = 0;
  dayEndHour = 23;
  dayStartMinute = 0;
  dayEndMinute = 59;
  contentInsertedFrom = new FormControl();
  contentInsertedTo = new FormControl();

  downloadLoading: boolean = false;

  channels: Array<ChannelBundle> = []
  channelsReady: boolean = false;

  typeOptions = [
    { 'value': Type.Adv, 'display': 'Only Advertisements', 'type': Type.Adv },
    { 'value': Type.Ondemand, 'display': 'Only Contents', 'type': Type.Ondemand },
  ]
  typeFilter = new FormControl();

  @ViewChild('screen') screen: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;
  @ViewChild('spinner') spinner: ElementRef;

  constructor(
    private retrieve: RetrieveService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.hourSegments = "12";
    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.typeFilter.setValue(params['type'] ? params['type'] : null, { emitEvent: false });
      this.showAnomalies.setValue(params['showAnomalies'] ? params['showAnomalies'] === 'true' : false, { emitEvent: false });

      this.enableDisableFilters();


      var dateNow = new Date()
      this.showscheduleDateRange.setValue({
        startDate: params['startDate'] ? moment(params['startDate']).toDate() : new Date(dateNow.getTime() - 86400000 * 7),
        endDate: params['endDate'] ? moment(params['endDate']).toDate() : dateNow,
      }, { emitEvent: false });

      this.contentInsertedFrom.setValue(params['contentInsertedFrom'] ? moment(params['contentInsertedFrom']) : null, { emitEvent: false });
      this.contentInsertedTo.setValue(params['contentInsertedTo'] ? moment(params['contentInsertedTo']) : null, { emitEvent: false });
      if (this.contentInsertedTo.value && this.contentInsertedFrom.value && this.contentInsertedTo.value <= this.contentInsertedFrom.value) {
        this.contentInsertedTo.setValue(moment(this.contentInsertedFrom.value).add(1, 'days').toDate())
      }

      this.hourFrom.setValue(params['hourFrom'] ? Math.min(Math.max(params['hourFrom'], 0), 23) : 0, { emitEvent: false });
      this.dayStartHour = this.hourFrom.value;
      this.hourTo.setValue(params['hourTo'] ? Math.min(Math.max(params['hourTo'], 0), 23) : 23, { emitEvent: false });
      this.dayEndHour = this.hourTo.value;
      this.minuteFrom.setValue(params['minuteFrom'] ? Math.min(Math.max(params['minuteFrom'], 0), 59) : 0, { emitEvent: false });
      this.dayStartMinute = this.minuteFrom.value;
      this.minuteTo.setValue(params['minuteTo'] ? Math.min(Math.max(params['minuteTo'], 0), 59) : 59, { emitEvent: false });
      this.dayEndMinute = this.minuteTo.value;

      if (this.hourTo.value < this.hourFrom.value) {
        this.hourTo.setValue(23, { emitEvent: false });
        this.minuteTo.setValue(59, { emitEvent: false });
      }

      this.viewDate = this.showscheduleDateRange.value.startDate;
      const diffTime = Math.abs(this.showscheduleDateRange.value.endDate - this.showscheduleDateRange.value.startDate);
      this.daysInWeek = Math.max(Math.floor(diffTime / (1000 * 60 * 60 * 24)) + 1, 1);
      this.channel.setValue(params['channel'] ? params['channel'] : null, { emitEvent: false });

      this.onlyFull.setValue(params['onlyFull'] ? params['onlyFull'] === 'true' : true, { emitEvent: false });
      this.showInterruption.setValue(params['showInterruption'] ? params['showInterruption'] === 'true' : false, { emitEvent: false });


      this.channelsReady = false;
      this.retrieve.getShowScheduleChannel({ onlyMainVersion: true }).subscribe(
        (channels) => {
          this.channels = channels;
          this.channelsReady = true;
        }
      );
      if (this.channel.value) {
        this.fetchEvents();
      }
    });
  }

  enableIfDisabled(control) {
    if (control.disabled) {
      control.enable();
    }
  }

  enableDisableFilters() {
    // this.startDateFrom.enable();
    this.enableIfDisabled(this.showscheduleDateRange);
    this.enableIfDisabled(this.hourFrom);
    this.enableIfDisabled(this.hourTo);
    this.enableIfDisabled(this.minuteFrom);
    this.enableIfDisabled(this.minuteTo);
    this.enableIfDisabled(this.channel);
    this.enableIfDisabled(this.onlyFull);
    this.enableIfDisabled(this.showInterruption);
    this.enableIfDisabled(this.showAnomalies);
    this.enableIfDisabled(this.typeFilter);

    this.exportDisabled = false;

    if (this.typeFilter.value == Type.Adv) {
      this.onlyFull.disable();
      this.showInterruption.disable();
    }

    if (this.showAnomalies.value) {
      this.onlyFull.disable();
      this.showInterruption.disable();
      this.typeFilter.disable();
      this.exportDisabled = true;
    }

  }

  ngAfterViewInit(): void {
    this.showscheduleDateRange.valueChanges.pipe(
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          startDate: moment(value.startDate).set({ minute: 0, hour: 0, second: 0, millisecond: 0 }),
          endDate: moment(value.endDate).set({ minute: 59, hour: 23, second: 59, millisecond: 0 }),
        },
        queryParamsHandling: 'merge'
      });
    });


    this.contentInsertedFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          contentInsertedFrom: value ? value.format('YYYY-MM-DD') : null,
          contentInsertedTo: value ? moment(value, 'YYYY-MM-DD').add(1, 'days') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.contentInsertedTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          contentInsertedTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });


    this.hourFrom.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          hourFrom: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.hourTo.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          hourTo: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.minuteFrom.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          minuteFrom: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.minuteTo.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          minuteTo: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.channel.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          channel: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.onlyFull.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          onlyFull: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.showInterruption.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          showInterruption: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.showAnomalies.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          showAnomalies: value,
        },
        queryParamsHandling: 'merge'
      });
    });


    this.typeFilter.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['viewshowschedule'], {
        queryParams: {
          type: value,
        },
        queryParamsHandling: 'merge'
      });
    });

  }

  searchEvent(showSchedules: Array<ShowSchedule>, searchAmid: string, startDate, endDate): number {
    let index = showSchedules.findIndex(({ amid, start_date, end_date }) => amid == searchAmid && moment(start_date) >= startDate && moment(end_date) <= endDate)
    return index
  }

  fetchEvents(): void {
    let currentDate = moment(this.viewDate);
    var offset = new Date().getTimezoneOffset();
    offset = -1 * offset;

    let weekStart = moment(this.showscheduleDateRange.value.startDate).set({ minute: 0, hour: 0, second: 0, millisecond: 0 });
    let weekEnd = moment(this.showscheduleDateRange.value.endDate).set({ minute: 59, hour: 23, second: 59, millisecond: 0 });

    if (!this.showAnomalies.value) {
      this.events$ = this.retrieve.getShowScheduleList({
        channel: this.channel.value,
        startDateFrom: weekStart.toISOString(),
        startDateTo: weekEnd.toISOString(),
        contentInsertedFrom: this.contentInsertedFrom.value ? moment(this.contentInsertedFrom.value).set({ minute: 0, hour: 0, second: 0, millisecond: 0 }).toISOString() : null,
        contentInsertedTo: this.contentInsertedTo.value ? moment(this.contentInsertedTo.value).set({ minute: 59, hour: 23, second: 59, millisecond: 0 }).toISOString() : null,
        onlyFull: false,
        type: this.typeFilter.value,
        partialfull_details: true,
        forExporting: true
      }).pipe(
        map((results) => {
          let shows: Array<CalendarEvent> = [];
          let addedPartialAmids = [];
          let addedAmids = [];

          let addedIndex = []
          results.forEach(
            (result: ShowSchedule, fullIndex: number) => {

              if (result.type == 'adv') {
                var randColor = '#79FE0C';
              } else {
                var letters = 'BCDEF'.split('');
                var randColor = '#';
                for (var i = 0; i < 6; i++) {
                  randColor += letters[Math.floor(Math.random() * letters.length)];
                }
              }

              //let randColor = Math.floor(Math.random() * 16777215).toString(16);
              let randomColor = {
                primary: randColor,
                secondary: randColor
              };

              if (this.onlyFull.value) {
                if (!(result.is_partial == "TRUE")) {
                  if (!addedIndex.includes(fullIndex)) {
                    shows.push(...this.computeEventsToAdd(result, randomColor, false, fullIndex, null, result.twins.length > 0));
                    addedIndex.push(fullIndex)
                    if (result.twins.length > 0) {
                      result.twins.forEach((twin) => {
                        const twinIndex = this.searchEvent(results, twin, moment(result.start_date).subtract(5, 'minutes'), moment(result.end_date).add(5, 'minutes'));
                        if (twinIndex >= 0 && !addedIndex.includes(twinIndex)) {
                          addedIndex.push(twinIndex)
                        }
                      })
                    }
                  }
                }
              } else {
                if (!(result.is_partial == "TRUE")) {
                  if (!addedIndex.includes(fullIndex)) {
                    shows.push(...this.computeEventsToAdd(result, randomColor, false, fullIndex, null, result.twins.length > 0))
                    addedIndex.push(fullIndex);
                    if (result.twins.length > 0) {
                      result.twins.forEach((twin) => {
                        const twinIndex = this.searchEvent(results, twin, moment(result.start_date).subtract(5, 'minutes'), moment(result.end_date).add(5, 'minutes'));
                        if (twinIndex >= 0 && !addedIndex.includes(twinIndex)) {
                          addedIndex.push(twinIndex)
                        }
                      })
                    }
                    if (result.partials) {
                      result.partials.forEach((partial) => {
                        const partialIndex = this.searchEvent(results, partial, moment(result.start_date).subtract(5, 'minutes'), moment(result.end_date).add(5, 'minutes'));

                        if (partialIndex >= 0 && !addedIndex.includes(partialIndex)) {
                          const partialEvent = results[partialIndex];
                          shows.push(...this.computeEventsToAdd(partialEvent, randomColor, true, partialIndex, null, partialEvent.twins.length > 0));
                          addedIndex.push(partialIndex);

                          if (partialEvent.twins.length > 0) {
                            partialEvent.twins.forEach((twin) => {
                              const twinIndex = this.searchEvent(results, twin, moment(partialEvent.start_date).subtract(5, 'minutes'), moment(partialEvent.end_date).add(5, 'minutes'));
                              if (twinIndex >= 0 && !addedIndex.includes(twinIndex)) {
                                addedIndex.push(twinIndex)
                              }
                            })
                          }
                        }
                      });
                    }
                  }
                }
              }
            }
          )

          if (!this.onlyFull.value) {
            const missingIndexes = Array.from(Array(results.length).keys()).filter(x => !addedIndex.includes(x));
            const redColor = {
              primary: '#ff3232',
              secondary: '#ff3232'
            };
            missingIndexes.forEach((missingIndex) => {
              if (!addedIndex.includes(missingIndex)) {
                const missingEvent = results[missingIndex];
                shows.push(...this.computeEventsToAdd(missingEvent, redColor, true, missingIndex, null, missingEvent.twins.length > 0));
                addedIndex.push(missingIndex);
                if (missingEvent.twins.length > 0) {
                  missingEvent.twins.forEach((twin) => {
                    const twinIndex = this.searchEvent(results, twin, moment(missingEvent.start_date).subtract(5, 'minutes'), moment(missingEvent.end_date).add(5, 'minutes'));
                    if (twinIndex >= 0 && !addedIndex.includes(twinIndex)) {
                      addedIndex.push(twinIndex)
                    }
                  })
                }

              }
            }
            )
          }
          return shows
        }
        )
      )
    } else {
      let editor = (this.channels.find(({ channel }) => channel === this.channel.value)).editor;
      this.events$ = this.retrieve.getShowScheduleAnomalies({
        channel: this.channel.value,
        startDateFrom: weekStart.toISOString(),
        startDateTo: weekEnd.toISOString(),
        editor: editor
      }).pipe(
        map((results) => {
          let shows: Array<CalendarEvent> = [];

          results.forEach(
            (anomalyType) => {

              let blueRandomColors = ['#482aa3', '#0c8bf9', '#3170b2', '#0e4b91', '#2575aa', '#106a6d', '#5e84c1', '#90cce5', '#2f828e']
              let redRandomColors = ['#ff7a8e', '#f9959e', '#ef437f', '#bf394f', '#dd6877', '#bf0d1f', '#e8333c', '#e8a99b', '#f99795']

              let showIdx = 0
              anomalyType.showschedules.forEach(
                (showschedules) => {
                  let randIdx = Math.floor(Math.random() * 10);
                  let randColor = '#FF0000'
                  if (anomalyType.type.includes('partial')) {
                    randColor = blueRandomColors[randIdx]
                  } else {
                    randColor = redRandomColors[randIdx]
                  }
                  let randomColor = {
                    primary: randColor,
                    secondary: randColor
                  };
                  showschedules.forEach(
                    (showschedule) => {
                      shows.push(...this.computeEventsToAdd(showschedule, randomColor, showschedule.is_partial == 'TRUE', showIdx, null, false));
                      showIdx++;
                    }
                  )
                }
              )
            }
          )
          return shows
        }
        ))
    }
  }

  getEventTitlePrefixString(start, end) {
    return '[' + start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }) + ' - ' + end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }) + '] '
  }

  computeLocaleDate(date) {
    // Assuming your UTC date is stored in a variable called utcDate
    const utcDate = new Date(date);

    var offset = utcDate.getTimezoneOffset();
    offset = -1 * offset;

    // Convert UTC date to local timezone
    const localDate = moment(date).add(offset, 'minutes').toDate();

    return localDate

  }

  computeEventsToAdd(showschedule: ShowSchedule, color, isPartial, id, customCss = null, isTwin = false): Array<CalendarEvent> {
    let shows: Array<CalendarEvent> = [];

    var showscheduleDateFrom = moment(showschedule.start_date);
    // Your moment at midnight
    var mmtMidnight = showscheduleDateFrom.clone().startOf('day');
    // Difference in minutes
    var diffMinutes = showscheduleDateFrom.diff(mmtMidnight, 'minutes');

    if (!(diffMinutes >= this.minuteFrom.value + this.hourFrom.value * 60) || !(diffMinutes <= this.minuteTo.value + this.hourTo.value * 60)) {
      return shows
    }

    let computeInterruptions: bool = false;
    if (this.showInterruption.value) {
      if ('interruptions' in showschedule.meta) {
        let interruptions = showschedule.meta['interruptions'] as any[];
        if (interruptions.length > 0) {
          computeInterruptions = true
        }
      }
    }

    if (computeInterruptions) {
      var offset = new Date().getTimezoneOffset();
      offset = -1 * offset;

      let interruptions = showschedule.meta['interruptions'] as Array<any>;
      interruptions.sort(function (a, b) {
        var keyA = moment(a.start);
        var keyB = moment(b.start);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });

      interruptions.forEach(
        (interruption) => {
          interruption.start = interruption.start.replace('+00:00', '')
          interruption.end = interruption.end.replace('+00:00', '')
        }
      )

      let start = moment(showschedule.start_date).toDate();
      let end = this.computeLocaleDate(interruptions[0].start);
      // moment(interruptions[0].start).add(offset, 'minutes').toDate();
      
      // let start = this.computeLocaleDate(showschedule.start_date)
      // let end = this.computeLocaleDate(interruptions[0].start)

      let title = ''
      if (showschedule.type == 'adv') {
        title = this.getEventTitlePrefixString(start, end) + showschedule.title + ' (adv)'
      } else {
        title = isTwin ? '* ' + this.getEventTitlePrefixString(start, end) + showschedule.title + ' (twin)' : this.getEventTitlePrefixString(start, end) + showschedule.title;
        title += isPartial ? ' (partial)' : ' (full)'
      }
      let ce: CalendarEvent = {
        title: title,
        start: start,
        end: end,
        draggable: false,
        meta: {
          'amid': showschedule.amid,
          'isPartial': isPartial,
          'id': id,
          'startOndemand': showschedule.meta['start_ondemand'],
          'endOndemand': interruptions[0]['end_ondemand'],
        },
        color: color
      };
      if (customCss) {
        ce['cssClass'] = customCss;
      }
      shows.push(ce)

      for (let i = 0; i < interruptions.length - 1; i++) {
        // let start = moment(interruptions[i].end).add(offset, 'minutes').toDate();
        // let end = moment(interruptions[i + 1].start).add(offset, 'minutes').toDate();
        
        let start = this.computeLocaleDate(interruptions[i].end);
        let end =  this.computeLocaleDate(interruptions[i + 1].start);
        if (showschedule.type == 'adv') {
          title = this.getEventTitlePrefixString(start, end) + showschedule.title + ' (adv)'
        } else {
          title = isTwin ? '* ' + this.getEventTitlePrefixString(start, end) + showschedule.title + ' (twin)' : this.getEventTitlePrefixString(start, end) + showschedule.title;
          title += isPartial ? ' (partial)' : ' (full)'
        }

        let ce: CalendarEvent = {
          title: title,
          start: start,
          end: end,
          draggable: false,
          meta: {
            'amid': showschedule.amid,
            'isPartial': isPartial,
            'id': id,
            'startOndemand': interruptions[i]['start_ondemand'],
            'endOndemand': interruptions[i + 1]['end_ondemand'],
          },
          color: color
        };
        if (customCss) {
          ce['cssClass'] = customCss;
        }
        shows.push(ce)
      }
      // start = moment(interruptions[interruptions.length - 1].end).add(offset, 'minutes').toDate();
      // end = moment(showschedule.end_date).toDate();
      start = this.computeLocaleDate(interruptions[interruptions.length - 1].end);
      end = moment(showschedule.end_date).toDate();
      if (showschedule.type == 'adv') {
        title = this.getEventTitlePrefixString(start, end) + showschedule.title + ' (adv)'
      } else {
        title = isTwin ? '* ' + this.getEventTitlePrefixString(start, end) + showschedule.title + ' (twin)' : this.getEventTitlePrefixString(start, end) + showschedule.title;
        title += isPartial ? ' (partial)' : ' (full)'
      }
      ce = {
        title: title,
        start: start,
        end: end,
        draggable: false,
        meta: {
          'amid': showschedule.amid,
          'isPartial': isPartial,
          'id': id,
          'startOndemand': interruptions[interruptions.length - 1]['start_ondemand'],
          'endOndemand': showschedule.meta['end_ondemand'],
        },
        color: color
      };
      if (customCss) {
        ce['cssClass'] = customCss;
      }
      shows.push(ce)
    } else {
      let start = moment(showschedule.start_date).toDate();
      let end = moment(showschedule.end_date).toDate();
      // let start = this.computeLocaleDate(showschedule.start_date);
      // let end = this.computeLocaleDate(showschedule.end_date);
      let title = ''
      if (showschedule.type == 'adv') {
        title = this.getEventTitlePrefixString(start, end) + showschedule.title + ' (adv)'
      } else {
        title = isTwin ? '* ' + this.getEventTitlePrefixString(start, end) + showschedule.title + ' (twin)' : this.getEventTitlePrefixString(start, end) + showschedule.title;
        title += isPartial ? ' (partial)' : ' (full)'
      }
      let ce: CalendarEvent = {
        title: title,
        start: start,
        end: end,
        draggable: false,
        meta: {
          'amid': showschedule.amid,
          'isPartial': isPartial,
          'id': id,
          'startOndemand': showschedule.meta['start_ondemand'],
          'endOndemand': showschedule.meta['end_ondemand'],
        },
        color: color
      };
      if (customCss) {
        ce['cssClass'] = customCss;
      }
      shows.push(ce)
    }

    return shows
  }

  eventClicked(event: CalendarEvent): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/content/${event.meta.amid}`])
    );

    window.open(url, '_blank');
  }

  dateRangeOverlaps(a_start, a_end, b_start, b_end) {
    let latestStart = a_start;
    if (b_start.getTime() > a_start.getTime()) {
      latestStart = b_start
    }

    let earliestEnd = a_end;
    if (a_end.getTime() > b_end.getTime()) {
      earliestEnd = b_end
    }

    return earliestEnd.getTime() > latestStart.getTime() ? earliestEnd.getTime() - latestStart.getTime() : -1
  }

  overlapInterval(a_start, a_end, b_start, b_end) {
    let latestStart = Math.max(a_start, b_start);
    let earliestEnd = Math.min(a_end, b_end);
    return earliestEnd > latestStart
  }

  /*  beforeViewRender(view: CalendarWeekViewBeforeRenderEvent) {
 
 
     view.hourColumns.forEach(column => {
       let dayEventsPos = []
       let insertedEvents = []
       column.events.forEach((event, enventIndex) => {
         if (event.width == 100 && !event.event.meta.isPartial) {
           dayEventsPos.push(
             {
               'left': event.left,
               'width': event.width,
               'start': event.event.start,
               'end': event.event.end,
             }
           )
           insertedEvents.push({
             'event': event,
             'eventIndex': enventIndex
           })
         } else {
           let timeOverlappedEvents = []
           insertedEvents.forEach((dayEvent) => {
             const timeOverlap = this.dateRangeOverlaps(dayEvent.event.event.start, dayEvent.event.event.end, event.event.start, event.event.end)
             if (timeOverlap > 1 * 1000) {
               timeOverlappedEvents.push(dayEvent);
             }
           })
 
 
           if (timeOverlappedEvents.length == 0){
             if (event.event.meta.isPartial){
               event.left = 0;
               event.width = 100;
             }else{
               event.left = 0;
               event.width = 100;
             }
           }else{
 
             // let allPartials = true;
             // for (let i = 0; i < timeOverlappedEvents.length; i++) {
             //   if (!timeOverlappedEvents[i].event.event.meta.isPartial){
             //     allPartials = false;
             //     break;
             //   }
             // }
 
 
             let offset = 0;
             let delta = 100 / (timeOverlappedEvents.length + 1);
 
             // if (allPartials){
             //   delta = 100 / (timeOverlappedEvents.length + 2);
             //   offset = delta;
             // }
 
             for (let i = 0; i < timeOverlappedEvents.length; i++) {
               delta = Math.min(delta, column.events[timeOverlappedEvents[i]['eventIndex']].width)
               column.events[timeOverlappedEvents[i]['eventIndex']].width = delta;
               column.events[timeOverlappedEvents[i]['eventIndex']].left = offset*delta;
               offset += 1;
             }
             event.width = delta;
             event.left = offset*delta;
           }
 
           insertedEvents.push({
             'event': event,
             'eventIndex': enventIndex
           })
 
           // let timeOverlappedEvents = []
           // dayEventsPos.forEach((dayEvent) => {
           //   const timeOverlap = this.dateRangeOverlaps(dayEvent.start, dayEvent.end, event.event.start, event.event.end)
           //   if (timeOverlap > 1 * 1000) {
           //     timeOverlappedEvents.push(dayEvent);
           //   }
           // })
 
           // let left = 0;
           // let width = event.width;
           // if (event.event.meta.isPartial) {
           //   if (timeOverlappedEvents.length > 0) {
           //     left = timeOverlappedEvents[0].width;
           //     width = timeOverlappedEvents[0].width;
           //   } else {
           //     left = Math.min(event.width, 100 - event.width);
           //     if (left == 0) {
           //       left = 50;
           //     }
           //     if (left == 100) {
           //       width = 100 / (100 / width + 1);
           //       left = width;
           //     } else if (left + width > 100) {
           //       width = 100 / (100 / width + 1);
           //       left = width;
           //     }
           //   }
           // }
 
 
           // let inserted = false;
           // while (!inserted) {
           //   inserted = true;
           //   for (let i = 0; i < timeOverlappedEvents.length; i++) {
           //     if (timeOverlappedEvents[i].left >= left) {
           //       if (this.overlapInterval(timeOverlappedEvents[i].left, timeOverlappedEvents[i].left + timeOverlappedEvents[i].width,
           //         left, left + width)) {
           //         inserted = false;
           //       }
           //     }
           //   }
           //   if (!inserted) {
           //     left += width;
           //     if (left == 100) {
           //       width = 100 / (100 / width + 1);
           //       left = width;
           //     } else if (left + width > 100) {
           //       width = 100 / (100 / width + 1);
           //       left = width;
           //     }
           //   }
           // }
 
           // dayEventsPos.push(
           //   {
           //     'left': left,
           //     'width': width,
           //     'start': event.event.start,
           //     'end': event.event.end
           //   }
           // )
 
           // event.left = left;
           // event.width = width;
 
         }
       })
     })
   }
  */
  downloadImage(format: string = 'jpeg') {
    let currentDate = moment(this.viewDate);
    let weekStart = currentDate.clone().startOf('week').startOf('day');
    let weekEnd = currentDate.clone().endOf('week').endOf('day');
    this.downloadLoading = true;

    let of = this.onlyFull.value ? '_onlyfull' : '';
    let interruptions = this.showInterruption.value ? '_interruption' : '';
    let hourSub = '_' + (Math.round(60 / parseFloat(this.hourSegments))).toString() + 'min';

    if (format == 'jpeg') {
      setTimeout(() => domtoimage.toJpeg(this.screen.nativeElement, { quality: 1.5 })
        .then(dataUrl => {
          this.downloadLink.nativeElement.href = dataUrl
          this.downloadLink.nativeElement.download = this.channel + '_' + weekStart.format('YYYY-MM-DD') + '_' + weekEnd.format('YYYY-MM-DD') + of + interruptions + hourSub + '.jpeg';
          this.downloadLink.nativeElement.click();
          this.downloadLoading = false;
        })
        .catch(error => {
          this.downloadLoading = false;
          console.error('oops, something went wrong!', error);
        }), 1000);
    } else {
      function filter(node) {
        return (node.tagName !== 'i');
      }
      setTimeout(() => domtoimage.toSvg(this.screen.nativeElement, { filter: filter })
        .then(dataUrl => {
          this.downloadLink.nativeElement.href = dataUrl
          this.downloadLink.nativeElement.download = this.channel + '_' + weekStart.format('YYYY-MM-DD') + '_' + weekEnd.format('YYYY-MM-DD') + of + interruptions + hourSub + '.svg';
          this.downloadLink.nativeElement.click();
          this.downloadLoading = false;
        })
        .catch(error => {
          this.downloadLoading = false;
          console.error('oops, something went wrong!', error);
        }), 1000);
    }
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  previousView() {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: moment(this.showscheduleDateRange.value.startDate).subtract(this.daysInWeek, 'days').set({ minute: 0, hour: 0, second: 0, millisecond: 0 }),
        endDate: moment(this.showscheduleDateRange.value.endDate).subtract(this.daysInWeek, 'days').set({ minute: 59, hour: 23, second: 59, millisecond: 0 }),
      },
      queryParamsHandling: 'merge'
    });
  }

  nextView() {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: moment(this.showscheduleDateRange.value.startDate).add(this.daysInWeek, 'days').set({ minute: 0, hour: 0, second: 0, millisecond: 0 }),
        endDate: moment(this.showscheduleDateRange.value.endDate).add(this.daysInWeek, 'days').set({ minute: 59, hour: 23, second: 59, millisecond: 0 }),
      },
      queryParamsHandling: 'merge'
    });
  }

  todayView() {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: moment().clone().startOf('week').startOf('day'),
        endDate: moment().clone().endOf('week').endOf('day')
      },
      queryParamsHandling: 'merge'
    });
  }

  resetView() {
    this.router.navigate(['viewshowschedule'], {
      queryParams: {
        startDate: moment().clone().startOf('week').startOf('day'),
        endDate: moment().clone().endOf('week').endOf('day'),
        hourFrom: 0,
        minuteFrom: 0,
        hourTo: 23,
        minuteTo: 59,
        channel: this.channel.value
      },
    });
  }

  downloadCSV() {
    let editor = (this.channels.find(({ channel }) => channel === this.channel.value)).editor;
    let startDate = moment(this.showscheduleDateRange.value.startDate)
    let endDate = moment(this.showscheduleDateRange.value.endDate)

    startDate.set({ hour: this.hourFrom.value, minute: this.minuteFrom.value, second: 0, millisecond: 0 })
    endDate.set({ hour: this.hourTo.value, minute: this.minuteTo.value, second: 0, millisecond: 0 })

    let fileName = this.channel.value + '_' + startDate.format('YYYY_MM_DD');
    if (startDate.toDate().getDate() != endDate.toDate().getDate()) {
      fileName += '_' + endDate.format('YYYY_MM_DD');
    }
    if (!(this.hourFrom.value == 0 && this.minuteFrom.value == 0 &&
      this.hourTo.value == 23 && this.minuteTo.value == 59)) {
      fileName += ' - ' + String(this.hourFrom.value).padStart(2, '0') + ':' + String(this.minuteFrom.value).padStart(2, '0')
        + '_' + String(this.hourTo.value).padStart(2, '0') + ':' + String(this.minuteTo.value).padStart(2, '0');
    }

    if (this.typeFilter.value) {
      fileName += '_' + this.typeFilter.value
    }

    fileName += '_showschedule'


    this.retrieve.exportShowScheduleList(
      {
        channel: this.channel.value,
        startDateFrom: startDate.toISOString(),
        startDateTo: endDate.toISOString(),
        contentInsertedFrom: this.contentInsertedFrom.value ? moment(this.contentInsertedFrom.value).set({ minute: 0, hour: 0, second: 0, millisecond: 0 }).toISOString() : null,
        contentInsertedTo: this.contentInsertedTo.value ? moment(this.contentInsertedTo.value).set({ minute: 59, hour: 23, second: 59, millisecond: 0 }).toISOString() : null,
        onlyFull: this.onlyFull.value,
        showInterruptions: this.showInterruption.value,
        editor: editor,
        type: this.typeFilter.value
      }
    ).subscribe(
      (data) => {
        this.downloadFile(data, 'csv', fileName);
      }
    )
  }

  downloadFile(items, fileType: string, filename = 'data') {

    let blob

    let csv = []
    let csvString

    const header = ['Date', 'Channel', 'Start time', 'End time', 'Title', 'Duration', 'Type'];
    csv = [header.join(';')] // header row first]

    let currentDate = new Date(items[0].start_date)
    items.forEach((item: any) => {
      let date = new Date(item.start_date).toLocaleDateString("it-IT");
      let startTime = moment(item.start_date).toISOString(true).substring(11, 19);
      let endTime = moment(item.end_date).toISOString(true).substring(11, 19);
      let duration = new Date(item.duration * 1000).toISOString().substring(11, 19);

      let tmpDate = new Date(item.start_date)
      if (tmpDate.getDate() !== currentDate.getDate()) {
        csv.push(['', '', '', '', '', '', ''].join(';'));
        currentDate = tmpDate;
      }
      csv.push([date,
        this.channel.value,
        startTime, endTime, item.title, duration, item.type].join(';'))
    });
    csvString = csv.join('\r\n')
    blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + fileType);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
