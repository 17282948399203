import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { Content } from '../api/models/content';
import { Observable, forkJoin, of, from, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Match } from '../api/models/match';
import { ContentDownloadService } from '../content-download.service'
import WaveSurfer from 'wavesurfer.js'
import WaveSurferRegions from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js';
import WaveSurferCursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js';
import { bool } from 'aws-sdk/clients/signer';
import { FormControl } from '@angular/forms';
import { Type } from '../api/models/type'
import moment from 'moment';
import { environment } from 'src/environments/environment';



export interface MatchesSearchParams {
  type?: Type,
  publishedFrom?: string;
  publishedTo?: string;
  onairFrom?: string;
  onairTo?: string;
  minDuration?: number;
  channel?: string
}

@Component({
  selector: 'app-matches-visualizer',
  templateUrl: './matches-visualizer.component.html',
  styleUrls: ['./matches-visualizer.component.css']
})
export class MatchesVisualizerComponent implements OnInit, AfterViewInit, OnDestroy {

  wavesurfer = null;

  types = ['ondemand', 'linear']

  matchesSearchParams: MatchesSearchParams = {}
  minDurationFilter = new FormControl();
  typeFilter = new FormControl()

  /* Datepickers helpers */
  onairFrom = new FormControl();
  onairTo = new FormControl();
  publishedFrom = new FormControl();
  publishedTo = new FormControl();
  channel = new FormControl();

  defaultFingerprintVersion = environment.defaultFingerprintVersion;
  fingerprintVersion = environment.defaultFingerprintVersion;
  content$: Observable<Content>;
  matches$: Observable<Match[]>;
  main_video_url: string;
  matched_videos = [];
  playing: bool = false;

  audioSource = 'main';

  matches = {};

  activeMatches = new Set();
  unactiveMatches = new Set();

  activeBackgroundColor = 'rgba(225, 0, 0, .5)';
  unactiveBackgroundColor = 'white';


  constructor(
    private retrieve: RetrieveService,
    private download: ContentDownloadService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnDestroy() {
    this.router.onSameUrlNavigation = 'ignore';
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.content$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.retrieve.getContent({ amid: params.get('amid') }))
    );


    combineLatest([this.route.params, this.route.queryParams]).subscribe(
      (results) => {

        this.fingerprintVersion = results[1]['version'] ? parseInt(results[1]['version']) : this.defaultFingerprintVersion;

        let minDuration = 'minDuration' in results[1] ? parseFloat(results[1]['minDuration']) : 0.
        this.minDurationFilter.setValue(minDuration, { emitEvent: false });
        this.matchesSearchParams['minDuration'] = this.minDurationFilter.value

        this.onairFrom.setValue(results[1]['onairFrom'] ? moment(results[1]['onairFrom']).toISOString() : null, { emitEvent: false });
        this.matchesSearchParams['onairFrom'] = this.onairFrom.value ? moment(this.onairFrom.value).set({ second: 0, millisecond: 0 }).toISOString() : null;

        this.onairTo.setValue(results[1]['onairTo'] ? moment(results[1]['onairTo']).toISOString() : null, { emitEvent: false });
        this.matchesSearchParams['onairTo'] = this.onairTo.value ? moment(this.onairTo.value).set({ second: 0, millisecond: 0 }).toISOString() : null;

        this.channel.setValue(results[1]['channel'] ? results[1]['channel'] : null, { emitEvent: false });
        this.matchesSearchParams['channel'] = this.channel.value ? this.channel.value : null;

        this.publishedFrom.setValue(results[1]['publishedFrom'] ? moment(results[1]['publishedFrom']).toISOString() : null, { emitEvent: false });
        this.matchesSearchParams['publishedFrom'] = this.publishedFrom.value ? moment(this.publishedFrom.value).set({ second: 0, millisecond: 0 }).toISOString() : null;

        this.publishedTo.setValue(results[1]['publishedTo'] ? moment(results[1]['publishedTo']).toISOString() : null, { emitEvent: false });
        this.matchesSearchParams['publishedTo'] = this.publishedTo.value ? moment(this.publishedTo.value).set({ second: 0, millisecond: 0 }).toISOString() : null;

        this.typeFilter.setValue(results[1]['type'] ? results[1]['type'] : null, { emitEvent: false });
        this.matchesSearchParams['type'] = this.typeFilter.value
        if (this.typeFilter.value == 'ondemand') {
          this.onairFrom.disable()
          this.onairTo.disable()
          this.channel.disable()
        } else {
          this.onairFrom.enable()
          this.onairTo.enable()
          this.channel.enable()
        }

        if (this.typeFilter.value == 'linear') {
          this.publishedFrom.disable()
          this.publishedTo.disable()
        } else {
          this.publishedFrom.enable()
          this.publishedTo.enable()
        }

        this.retrieve.getMatches({ amid: results[0].amid, version: this.fingerprintVersion, ...this.matchesSearchParams }).subscribe(
          matched_contents => {
            let matchedContentsRetrieve = []
            let matchedAmids = {}
            let match_ids = []
            matched_contents.forEach((match: Match) => {
              matchedContentsRetrieve.push(this.retrieve.getContent({ amid: match.otheramid }));

              if (!(match.otheramid in matchedAmids)) {
                match_ids.push(match.otheramid)
                matchedAmids[match.otheramid] = 1
                this.matches[match.otheramid] = { 'duration': match.duration, 'otheroffset': match.otheroffset, 'selfoffset': match.selfoffset }
                this.unactiveMatches.add(match.otheramid);
              } else {
                match_ids.push(match.otheramid + '_' + matchedAmids[match.otheramid])
                this.unactiveMatches.add(match.otheramid + '_' + matchedAmids[match.otheramid]);
                this.matches[match.otheramid + '_' + matchedAmids[match.otheramid]] = { 'duration': match.duration, 'otheroffset': match.otheroffset, 'selfoffset': match.selfoffset }
                matchedAmids[match.otheramid] = matchedAmids[match.otheramid] + 1
              }
            })

            this.drawRegions();

            forkJoin(
              matchedContentsRetrieve
            ).subscribe(
              (matched_contents: Array<Content>) => {
                this.matched_videos = []
                let idx = 0;
                matched_contents.forEach((content: Content) => {
                  if (content.download.videoUri) {
                    this.matched_videos.push({ 'amid': match_ids[idx], 'video_url':  this.download.videoDownloadSync(content.download.videoUri) })
                  } else if (content.download.audioUri) {
                    this.matched_videos.push({ 'amid': match_ids[idx], 'video_url':  this.download.videoDownloadSync(content.download.audioUri) })
                  }
                  idx = idx + 1;
                }
                )
              }
            )

          }
        )
      }
    );
  }


  reloadPage(event: any) {
    this.router.navigate([window.location.pathname], { queryParams: { ...this.matchesSearchParams } });
  }

  ngAfterViewInit(): void {

    this.typeFilter.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['type'] = value
        if (value == 'ondemand') {
          this.onairFrom.disable()
          this.onairTo.disable()
          this.channel.disable()
        } else {
          this.onairFrom.enable()
          this.onairTo.enable()
          this.channel.enable()
        }

        if (value == 'linear') {
          this.publishedFrom.disable()
          this.publishedTo.disable()
        } else {
          this.publishedFrom.enable()
          this.publishedTo.enable()
        }
      });

    this.minDurationFilter.valueChanges.subscribe(
      value => {
        this.matchesSearchParams['minDuration'] = value
      });

    this.onairFrom.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['onairFrom'] = value ? moment(value).set({ second: 0, millisecond: 0 }).toISOString() : null;
      });

    this.onairTo.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['onairTo'] = value ? moment(value).set({ second: 0, millisecond: 0 }).toISOString() : null;
      });

    this.channel.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['channel'] = value ? value : null;
      });

    this.publishedFrom.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['publishedFrom'] = value ? moment(value).set({ second: 0, millisecond: 0 }).toISOString() : null;
      });

    this.publishedTo.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['publishedTo'] = value ? moment(value).set({ second: 0, millisecond: 0 }).toISOString() : null;
      });

    requestAnimationFrame(() => {
      this.wavesurfer = WaveSurfer.create({
        container: '#waveform',
        waveColor: '#A8DBA8',
        progressColor: '#3B8686',
        backend: 'MediaElement',
        plugins: [
          WaveSurferRegions.create(),
          WaveSurferCursor.create({
            showTime: true,
            opacity: 1,
            customShowTimeStyle: {
              'background-color': '#000',
              color: '#fff',
              padding: '2px',
              'font-size': '10px'
            }
          })
        ]
      });
      this.wavesurfer.on('error', function (e) {
        console.warn(e);
      });

      this.wavesurfer.on('region-in', (region, e) => {
        this.setActiveMatch(region.id);
      });

      this.wavesurfer.on('region-out', (region, e) => {
        this.setUnactiveMatch(region.id);
      });

      this.wavesurfer.on('seek', () => {
        for (let amid in this.matches) {
          if (this.isCursorInRegion(amid)) {
            this.setActiveMatch(amid, this.getCurrentTime(amid));
          } else {
            this.setUnactiveMatch(amid);
          }
        }
        var cursor_pos = this.wavesurfer.getCurrentTime();
        var mainVideo: any = document.getElementById("mainVideo");
        mainVideo.currentTime = cursor_pos;
      });
    });

    //this.drawRegions();

    this.muteAll();


    this.content$.subscribe(
      (x: Content) => {
        if (x.download.videoUri) {
          this.download.videoDownload(x.download.videoUri).then(
            (url) => {
              this.main_video_url = url;
              this.wavesurfer.load(this.main_video_url);
            }
          )
        }
      }
    );
  }

  pauseAll(): void {
    for (let amid in this.matches) {
      this.setPause(amid);
    }
  }

  playAll(): void {
    for (let amid of this.activeMatches) {
      this.setCurrentTime(<string>amid, this.getCurrentTime(<string>amid))
      this.setPlay(<string>amid);
    }
  }

  muteAll(): void {
    for (let amid in this.matches) {
      this.setMuted(amid, true);
    }
  }

  setActiveMatch(amid: string, currentTime: number = -1): void {
    this.unactiveMatches.delete(amid);
    this.activeMatches.add(amid);

    let ct = 0;
    if (currentTime == -1) {
      ct = this.matches[amid]['otheroffset']
    } else {
      ct = currentTime;
    }
    this.setCurrentTime(amid, ct);
    if (this.playing) {
      this.setPlay(amid);
    }
    this.setActiveBackground(amid);
  }

  setUnactiveMatch(amid: string): void {
    this.activeMatches.delete(amid);
    this.unactiveMatches.add(amid);
    this.setMuted(amid, true);
    this.audioSource = 'main';
    this.wavesurfer.setMute(false);
    this.setPause(amid);
    this.setUnactiveBackground(amid);
  }

  setCurrentTime(videoId: string, currentTime: number): void {
    var video: any = document.getElementById(videoId);
    video.currentTime = currentTime;
  }

  setPlay(amid: string): void {
    var video: any = document.getElementById(amid);
    if (this.audioSource !== amid) {
      video.muted = true;
    }
    video.play();
  }

  setPause(amid: string): void {
    var video: any = document.getElementById(amid);
    video.pause();
  }

  setMuted(amid: string, muted: bool): void {
    var video: any = document.getElementById(amid);
    video.muted = muted;
  }

  setActiveBackground(amid: string): void {
    var div: any = document.getElementById(amid + "-card");
    div.style.backgroundColor = this.activeBackgroundColor;
  }

  setUnactiveBackground(amid: string): void {
    var div: any = document.getElementById(amid + "-card");
    div.style.backgroundColor = this.unactiveBackgroundColor;
  }

  getCurrentTime(amid: string): number {
    let currenPos = this.wavesurfer.getCurrentTime();
    return this.matches[amid]['otheroffset'] + (currenPos - this.matches[amid]['selfoffset']);
  }

  isCursorInRegion(amid: string): boolean {
    let currenPos = this.wavesurfer.getCurrentTime();
    let regionStart = this.matches[amid]['selfoffset'];
    let regionEnd = this.matches[amid]['selfoffset'] + this.matches[amid]['duration'];
    if (currenPos <= regionEnd && currenPos >= regionStart) {
      return true;
    }
    return false;
  }

  startVideo() {
    this.playing = !this.playing;
    var mainVideo: any = document.getElementById("mainVideo");
    if (this.playing) {
      this.wavesurfer.play();
      mainVideo.muted = true;
      mainVideo.play();
      this.playAll();
    }
    else {
      this.wavesurfer.pause();
      mainVideo.pause();
      this.pauseAll();
    }

  }

  drawRegions() {

    for (let key in this.matches) {
      this.wavesurfer.addRegion(
        {
          'id': key,
          'start': this.matches[key]['selfoffset'],
          'end': this.matches[key]['selfoffset'] + this.matches[key]['duration'],
          'drag': false,
          'resize': false,
          'color': this.randomColor(0.25)
        }
      )
    }
  }

  onClick(event: Event): void {
    let amid: string = (event.target as Element).id;
    this.wavesurfer.seekTo(this.matches[amid]['selfoffset'] / this.wavesurfer.getDuration());
    this.setActiveMatch(amid);
  }

  selectAudioSource(amid: string): void {
    if (this.audioSource == amid) {
      this.audioSource = 'main';
      this.setMuted(amid, true);
      this.wavesurfer.setMute(false);
    } else {
      if (this.activeMatches.has(amid)) {
        if (this.audioSource == 'main') {
          this.wavesurfer.setMute(true);
        } else {
          this.setMuted(this.audioSource, true);
        }
        this.setMuted(amid, false);
        this.audioSource = amid;
      }
    }
  }

  randomColor(alpha: number) {
    return (
      'rgba(' +
      [
        ~~(Math.random() * 255),
        ~~(Math.random() * 255),
        ~~(Math.random() * 255),
        alpha || 1
      ] +
      ')'
    );
  }

}
