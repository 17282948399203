/* tslint:disable */
/* eslint-disable */
export enum Metrics {
  CountContentsDailyByDate = 'count_contents_daily_by_date',
  CountContentsCumulatedByDate = 'count_contents_cumulated_by_date',
  CountContentsTotByEditor = 'count_contents_tot_by_editor',
  FailedDownloadDailyByDate = 'failed_download_daily_by_date',
  FailedDownloadCumulatedByDate = 'failed_download_cumulated_by_date',
  FailedDownloadTotByEditor = 'failed_download_tot_by_editor',
  DownloadStatus = 'download_status',
  DownloadErrorByType = 'download_error_by_type',
  FailedFingerprintDailyByDate = 'failed_fingerprint_daily_by_date',
  FailedFingerprintCumulatedByDate = 'failed_fingerprint_cumulated_by_date',
  FailedFingerprintTotByEditor = 'failed_fingerprint_tot_by_editor',
  AudiofeatStatus = 'audiofeat_status',
  AudiofeatErrorByType = 'audiofeat_error_by_type',
  AudiomatchContentsStatus = 'audiomatch_contents_status',
  AudiomatchRunsStatus = 'audiomatch_runs_status',
  AudiomatchErrorByType = 'audiomatch_error_by_type',
  ContentsDurationDailyByDate = 'contents_duration_daily_by_date',
  ContentsDurationCumulatedByDate = 'contents_duration_cumulated_by_date',
  ContentsDurationTotByEditor = 'contents_duration_tot_by_editor',
  ContentsSizeDailyByDate = 'contents_size_daily_by_date',
  ContentsSizeCumulatedByDate = 'contents_size_cumulated_by_date',
  ContentsSizeTotByFiletype = 'contents_size_tot_by_filetype',
  ContentsSizeTotByEditor = 'contents_size_tot_by_editor',
  ShowscheduleStatus = 'showschedule_status',
  ShowscheduleStatusByChannel = 'showschedule_status_by_channel',
  ShowscheduleCompleteness = 'showschedule_completeness',
  ShowscheduleGenre = 'showschedule_genre',
  ShowscheduleGenreConfidence = 'showschedule_genre_confidence',
  FailedGenreDailyByDate = 'failed_genre_daily_by_date',
  FailedGenreTotByEditor = 'failed_genre_tot_by_editor',
  GenreStatus = 'genre_status',
  GenreErrorByType = 'genre_error_by_type'
}
