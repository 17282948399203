/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Amid } from '../models/amid';
import { AudioFeatSuccess } from '../models/audio-feat-success';
import { AudioMatchError } from '../models/audio-match-error';
import { AudioMatchJob } from '../models/audio-match-job';
import { AudioMatchProgress } from '../models/audio-match-progress';
import { AudioMatchSuccess } from '../models/audio-match-success';
import { AutoPolicy } from '../models/auto-policy';
import { Channel } from '../models/channel';
import { DateTime } from '../models/date-time';
import { DownloadChunk } from '../models/download-chunk';
import { DownloadSuccess } from '../models/download-success';
import { Editor } from '../models/editor';
import { Error } from '../models/error';
import { FingerprintVersion } from '../models/fingerprint-version';
import { GenreSuccess } from '../models/genre-success';
import { IndexSuccess } from '../models/index-success';
import { IndexUri } from '../models/index-uri';
import { IndexingError } from '../models/indexing-error';
import { JobId } from '../models/job-id';
import { MatchPolicy } from '../models/match-policy';
import { MaxNum } from '../models/max-num';
import { MergeError } from '../models/merge-error';
import { MergeSuccess } from '../models/merge-success';
import { MonitoredChannel } from '../models/monitored-channel';
import { MonitoredChannelError } from '../models/monitored-channel-error';
import { MonitoredChannelUdPport } from '../models/monitored-channel-ud-pport';
import { OnpremiseServerId } from '../models/onpremise-server-id';
import { ProcessIndexingQueue } from '../models/process-indexing-queue';
import { RedundancyRunError } from '../models/redundancy-run-error';
import { RedundancyRunSuccess } from '../models/redundancy-run-success';
import { SampleMetric } from '../models/sample-metric';
import { SimpleMonitoredChannel } from '../models/simple-monitored-channel';
import { SubmitRedundancy } from '../models/submit-redundancy';
import { SubmitShowSchedule } from '../models/submit-show-schedule';
import { UpdateStorageClassContent } from '../models/update-storage-class-content';


/**
 * Operate on contents
 */
@Injectable({
  providedIn: 'root',
})
export class OperateService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation updateStorageClass
   */
  static readonly UpdateStorageClassPath = '/contents/storage-class';

  /**
   * Update storage class of contents for a given set of parameters.
   *
   * Update storage class of contents for a given set of parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStorageClass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateStorageClass$Response(params: {
    body: UpdateStorageClassContent
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.UpdateStorageClassPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update storage class of contents for a given set of parameters.
   *
   * Update storage class of contents for a given set of parameters
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateStorageClass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateStorageClass(params: {
    body: UpdateStorageClassContent
  }): Observable<void> {

    return this.updateStorageClass$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queueDownload
   */
  static readonly QueueDownloadPath = '/content/{amid}/download/queue';

  /**
   * Queue the content for download.
   *
   * Queue the content for download from the URL provided at insertion time.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueDownload$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Quality of video for download
     */
    quality?: 'min' | 'max';

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;

    /**
     * The task is being performed outside AWS. A string is provided and saved as job id.
     */
    external?: string;
  }): Observable<StrictHttpResponse<JobId>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueueDownloadPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('quality', params.quality, {});
      rb.query('force', params.force, {});
      rb.query('external', params.external, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobId>;
      })
    );
  }

  /**
   * Queue the content for download.
   *
   * Queue the content for download from the URL provided at insertion time.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueDownload(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Quality of video for download
     */
    quality?: 'min' | 'max';

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;

    /**
     * The task is being performed outside AWS. A string is provided and saved as job id.
     */
    external?: string;
  }): Observable<JobId> {

    return this.queueDownload$Response(params).pipe(
      map((r: StrictHttpResponse<JobId>) => r.body as JobId)
    );
  }

  /**
   * Path part for operation setDownloadChunks
   */
  static readonly SetDownloadChunksPath = '/content/{amid}/download/chunk';

  /**
   * Set download chunk uploaded.
   *
   * Set download chunk is uploaded to S3 by providing audio and/or video fragment uri
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDownloadChunks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDownloadChunks$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
    body?: DownloadChunk
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetDownloadChunksPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('force', params.force, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set download chunk uploaded.
   *
   * Set download chunk is uploaded to S3 by providing audio and/or video fragment uri
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setDownloadChunks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDownloadChunks(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
    body?: DownloadChunk
  }): Observable<void> {

    return this.setDownloadChunks$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setDownloadSuccess
   */
  static readonly SetDownloadSuccessPath = '/content/{amid}/download/success';

  /**
   * Set download successful.
   *
   * Set download success by providing audio and video uri
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDownloadSuccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDownloadSuccess$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
    body?: DownloadSuccess
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetDownloadSuccessPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('force', params.force, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set download successful.
   *
   * Set download success by providing audio and video uri
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setDownloadSuccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDownloadSuccess(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
    body?: DownloadSuccess
  }): Observable<void> {

    return this.setDownloadSuccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setDownloadError
   */
  static readonly SetDownloadErrorPath = '/content/{amid}/download/error';

  /**
   * Set download error.
   *
   * Set download error and eventually add a message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setDownloadError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDownloadError$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;
    body?: Error
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetDownloadErrorPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set download error.
   *
   * Set download error and eventually add a message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setDownloadError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setDownloadError(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;
    body?: Error
  }): Observable<void> {

    return this.setDownloadError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queueAudiofeat
   */
  static readonly QueueAudiofeatPath = '/content/{amid}/audiofeat/queue';

  /**
   * Queue the content for audio feature extraction.
   *
   * Queue the content for audio feature extraction with the provided extractor version.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueAudiofeat()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueAudiofeat$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;

    /**
     * The task is being performed outside AWS. A string is provided and saved as job id.
     */
    external?: string;
  }): Observable<StrictHttpResponse<JobId>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueueAudiofeatPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.query('force', params.force, {});
      rb.query('external', params.external, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobId>;
      })
    );
  }

  /**
   * Queue the content for audio feature extraction.
   *
   * Queue the content for audio feature extraction with the provided extractor version.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueAudiofeat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueAudiofeat(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;

    /**
     * The task is being performed outside AWS. A string is provided and saved as job id.
     */
    external?: string;
  }): Observable<JobId> {

    return this.queueAudiofeat$Response(params).pipe(
      map((r: StrictHttpResponse<JobId>) => r.body as JobId)
    );
  }

  /**
   * Path part for operation setAudiofeatSuccess
   */
  static readonly SetAudiofeatSuccessPath = '/content/{amid}/audiofeat/success';

  /**
   * Set audio feature extraction success.
   *
   * Set audio feature extraction success and eventually upload the fingerprint
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAudiofeatSuccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAudiofeatSuccess$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
    body?: AudioFeatSuccess
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetAudiofeatSuccessPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.query('force', params.force, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set audio feature extraction success.
   *
   * Set audio feature extraction success and eventually upload the fingerprint
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setAudiofeatSuccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAudiofeatSuccess(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
    body?: AudioFeatSuccess
  }): Observable<void> {

    return this.setAudiofeatSuccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setAudiofeatError
   */
  static readonly SetAudiofeatErrorPath = '/content/{amid}/audiofeat/error';

  /**
   * Set audio feature extraction error.
   *
   * Set audio feature extraction error and eventually add a message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAudiofeatError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAudiofeatError$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;
    body?: Error
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetAudiofeatErrorPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set audio feature extraction error.
   *
   * Set audio feature extraction error and eventually add a message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setAudiofeatError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAudiofeatError(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;
    body?: Error
  }): Observable<void> {

    return this.setAudiofeatError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queueIndexing
   */
  static readonly QueueIndexingPath = '/content/{amid}/indexing/queue';

  /**
   * Queue the content for audio feature indexing.
   *
   * Queue the content for audio feature indexing with the provided fingerprint version.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueIndexing()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueIndexing$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Force the deletion of the fingerprint if indexing succeed
     */
    delete_fingerprint?: boolean;

    /**
     * index_uri
     */
    index_uri?: IndexUri;
  }): Observable<StrictHttpResponse<JobId>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueueIndexingPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.query('delete_fingerprint', params.delete_fingerprint, {});
      rb.query('index_uri', params.index_uri, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobId>;
      })
    );
  }

  /**
   * Queue the content for audio feature indexing.
   *
   * Queue the content for audio feature indexing with the provided fingerprint version.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueIndexing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueIndexing(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;

    /**
     * Force the deletion of the fingerprint if indexing succeed
     */
    delete_fingerprint?: boolean;

    /**
     * index_uri
     */
    index_uri?: IndexUri;
  }): Observable<JobId> {

    return this.queueIndexing$Response(params).pipe(
      map((r: StrictHttpResponse<JobId>) => r.body as JobId)
    );
  }

  /**
   * Path part for operation processIndexingQueue
   */
  static readonly ProcessIndexingQueuePath = '/content/indexing/processqueue';

  /**
   * Process indexing queue.
   *
   * Process indexing queue
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processIndexingQueue()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processIndexingQueue$Response(params?: {
    body?: ProcessIndexingQueue
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.ProcessIndexingQueuePath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Process indexing queue.
   *
   * Process indexing queue
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `processIndexingQueue$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processIndexingQueue(params?: {
    body?: ProcessIndexingQueue
  }): Observable<void> {

    return this.processIndexingQueue$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setIndexingSuccess
   */
  static readonly SetIndexingSuccessPath = '/content/indexing/success';

  /**
   * Set audio feature indexing success.
   *
   * Set audio feature indexing success
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setIndexingSuccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setIndexingSuccess$Response(params?: {
    body?: IndexSuccess
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetIndexingSuccessPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set audio feature indexing success.
   *
   * Set audio feature indexing success
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setIndexingSuccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setIndexingSuccess(params?: {
    body?: IndexSuccess
  }): Observable<void> {

    return this.setIndexingSuccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setIndexingError
   */
  static readonly SetIndexingErrorPath = '/content/indexing/error';

  /**
   * Set audio feature indexing error.
   *
   * Set audio feature indexing error and eventually add a message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setIndexingError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setIndexingError$Response(params?: {
    body?: IndexingError
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetIndexingErrorPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set audio feature indexing error.
   *
   * Set audio feature indexing error and eventually add a message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setIndexingError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setIndexingError(params?: {
    body?: IndexingError
  }): Observable<void> {

    return this.setIndexingError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queueGenre
   */
  static readonly QueueGenrePath = '/content/{amid}/genre/queue';

  /**
   * Queue the content for genre classification.
   *
   * Queue the content for genre classification.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueGenre()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueGenre$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
  }): Observable<StrictHttpResponse<JobId>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueueGenrePath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('force', params.force, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobId>;
      })
    );
  }

  /**
   * Queue the content for genre classification.
   *
   * Queue the content for genre classification.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueGenre$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueGenre(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
  }): Observable<JobId> {

    return this.queueGenre$Response(params).pipe(
      map((r: StrictHttpResponse<JobId>) => r.body as JobId)
    );
  }

  /**
   * Path part for operation setGenreSuccess
   */
  static readonly SetGenreSuccessPath = '/content/{amid}/genre/success';

  /**
   * Set genre successful.
   *
   * Set genre success by providing features uri and level class probabilities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setGenreSuccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setGenreSuccess$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
    body?: GenreSuccess
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetGenreSuccessPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('force', params.force, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set genre successful.
   *
   * Set genre success by providing features uri and level class probabilities
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setGenreSuccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setGenreSuccess(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Force the operation even if content state is inconsistent
     */
    force?: boolean;
    body?: GenreSuccess
  }): Observable<void> {

    return this.setGenreSuccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setGenreError
   */
  static readonly SetGenreErrorPath = '/content/{amid}/genre/error';

  /**
   * Set genre error.
   *
   * Set genre error and eventually add a message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setGenreError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setGenreError$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;
    body?: Error
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetGenreErrorPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set genre error.
   *
   * Set genre error and eventually add a message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setGenreError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setGenreError(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;
    body?: Error
  }): Observable<void> {

    return this.setGenreError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queueAudiomatch
   */
  static readonly QueueAudiomatchPath = '/content/{amid}/audiomatch/queue';

  /**
   * Queue the content for audio matching.
   *
   * Queue the content for audio feature matching with the provided extractor version. Default policies can be overritten.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueAudiomatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queueAudiomatch$Response(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;
    body?: MatchPolicy
  }): Observable<StrictHttpResponse<JobId>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueueAudiomatchPath, 'patch');
    if (params) {
      rb.path('amid', params.amid, {});
      rb.query('version', params.version, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobId>;
      })
    );
  }

  /**
   * Queue the content for audio matching.
   *
   * Queue the content for audio feature matching with the provided extractor version. Default policies can be overritten.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueAudiomatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queueAudiomatch(params: {

    /**
     * AudioMatch ID of the content
     */
    amid: Amid;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;
    body?: MatchPolicy
  }): Observable<JobId> {

    return this.queueAudiomatch$Response(params).pipe(
      map((r: StrictHttpResponse<JobId>) => r.body as JobId)
    );
  }

  /**
   * Path part for operation setAudiomatchSuccess
   */
  static readonly SetAudiomatchSuccessPath = '/content/audiomatch/success';

  /**
   * Set audio match success.
   *
   * Set audio feature matching success and upload the total elapsed time
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAudiomatchSuccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAudiomatchSuccess$Response(params: {
    body: AudioMatchSuccess
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetAudiomatchSuccessPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set audio match success.
   *
   * Set audio feature matching success and upload the total elapsed time
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setAudiomatchSuccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAudiomatchSuccess(params: {
    body: AudioMatchSuccess
  }): Observable<void> {

    return this.setAudiomatchSuccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateAudiomatchProgress
   */
  static readonly UpdateAudiomatchProgressPath = '/content/audiomatch/progress';

  /**
   * Update audio match progress.
   *
   * Update audio match progress and upload the matches
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAudiomatchProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAudiomatchProgress$Response(params: {
    body: AudioMatchProgress
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.UpdateAudiomatchProgressPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update audio match progress.
   *
   * Update audio match progress and upload the matches
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAudiomatchProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAudiomatchProgress(params: {
    body: AudioMatchProgress
  }): Observable<void> {

    return this.updateAudiomatchProgress$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setAudiomatchError
   */
  static readonly SetAudiomatchErrorPath = '/content/audiomatch/error';

  /**
   * Set audio feature matching error.
   *
   * Set audio feature matching error and eventually add a message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setAudiomatchError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAudiomatchError$Response(params: {
    body: AudioMatchError
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetAudiomatchErrorPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set audio feature matching error.
   *
   * Set audio feature matching error and eventually add a message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setAudiomatchError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setAudiomatchError(params: {
    body: AudioMatchError
  }): Observable<void> {

    return this.setAudiomatchError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setOnpremiseServerError
   */
  static readonly SetOnpremiseServerErrorPath = '/onpremiseserver/{id}/error';

  /**
   * Set onpremise server error.
   *
   * Set onpremise server error and eventually add a message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setOnpremiseServerError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setOnpremiseServerError$Response(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;
    body: Error
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetOnpremiseServerErrorPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set onpremise server error.
   *
   * Set onpremise server error and eventually add a message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setOnpremiseServerError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setOnpremiseServerError(params: {

    /**
     * OnpremiseServer ID
     */
    id: OnpremiseServerId;
    body: Error
  }): Observable<void> {

    return this.setOnpremiseServerError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setShowscheduleOn
   */
  static readonly SetShowscheduleOnPath = '/monitoredchannel/showschedule/on';

  /**
   * Set show schedule on for monitored channel.
   *
   * Set show schedule on for monitored channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setShowscheduleOn()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setShowscheduleOn$Response(params: {
    body: SimpleMonitoredChannel
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetShowscheduleOnPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set show schedule on for monitored channel.
   *
   * Set show schedule on for monitored channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setShowscheduleOn$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setShowscheduleOn(params: {
    body: SimpleMonitoredChannel
  }): Observable<void> {

    return this.setShowscheduleOn$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setShowscheduleOff
   */
  static readonly SetShowscheduleOffPath = '/monitoredchannel/showschedule/off';

  /**
   * Set show schedule off for monitored channel.
   *
   * Set show schedule off for monitored channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setShowscheduleOff()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setShowscheduleOff$Response(params: {
    body: SimpleMonitoredChannel
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetShowscheduleOffPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set show schedule off for monitored channel.
   *
   * Set show schedule off for monitored channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setShowscheduleOff$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setShowscheduleOff(params: {
    body: SimpleMonitoredChannel
  }): Observable<void> {

    return this.setShowscheduleOff$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation startMonitoringChannel
   */
  static readonly StartMonitoringChannelPath = '/monitoredchannel/start';

  /**
   * Start monitoring channel.
   *
   * Start monitoring linear channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startMonitoringChannel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startMonitoringChannel$Response(params: {
    body: SimpleMonitoredChannel
  }): Observable<StrictHttpResponse<MonitoredChannel>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.StartMonitoringChannelPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoredChannel>;
      })
    );
  }

  /**
   * Start monitoring channel.
   *
   * Start monitoring linear channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startMonitoringChannel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startMonitoringChannel(params: {
    body: SimpleMonitoredChannel
  }): Observable<MonitoredChannel> {

    return this.startMonitoringChannel$Response(params).pipe(
      map((r: StrictHttpResponse<MonitoredChannel>) => r.body as MonitoredChannel)
    );
  }

  /**
   * Path part for operation startSuccessMonitoringChannel
   */
  static readonly StartSuccessMonitoringChannelPath = '/monitoredchannel/start/success';

  /**
   * Successful start for monitoring channel.
   *
   * Successful start for monitoring channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSuccessMonitoringChannel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startSuccessMonitoringChannel$Response(params: {
    body: SimpleMonitoredChannel
  }): Observable<StrictHttpResponse<MonitoredChannel>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.StartSuccessMonitoringChannelPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoredChannel>;
      })
    );
  }

  /**
   * Successful start for monitoring channel.
   *
   * Successful start for monitoring channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `startSuccessMonitoringChannel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  startSuccessMonitoringChannel(params: {
    body: SimpleMonitoredChannel
  }): Observable<MonitoredChannel> {

    return this.startSuccessMonitoringChannel$Response(params).pipe(
      map((r: StrictHttpResponse<MonitoredChannel>) => r.body as MonitoredChannel)
    );
  }

  /**
   * Path part for operation stopMonitoringChannel
   */
  static readonly StopMonitoringChannelPath = '/monitoredchannel/stop';

  /**
   * Stop monitoring channel.
   *
   * Stop monitoring linear channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopMonitoringChannel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stopMonitoringChannel$Response(params: {
    body: SimpleMonitoredChannel
  }): Observable<StrictHttpResponse<MonitoredChannel>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.StopMonitoringChannelPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoredChannel>;
      })
    );
  }

  /**
   * Stop monitoring channel.
   *
   * Stop monitoring linear channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopMonitoringChannel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stopMonitoringChannel(params: {
    body: SimpleMonitoredChannel
  }): Observable<MonitoredChannel> {

    return this.stopMonitoringChannel$Response(params).pipe(
      map((r: StrictHttpResponse<MonitoredChannel>) => r.body as MonitoredChannel)
    );
  }

  /**
   * Path part for operation stopSuccessMonitoringChannel
   */
  static readonly StopSuccessMonitoringChannelPath = '/monitoredchannel/stop/success';

  /**
   * Successful stop for monitoring channel.
   *
   * Successful stop for monitoring channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `stopSuccessMonitoringChannel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stopSuccessMonitoringChannel$Response(params: {
    body: SimpleMonitoredChannel
  }): Observable<StrictHttpResponse<MonitoredChannel>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.StopSuccessMonitoringChannelPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MonitoredChannel>;
      })
    );
  }

  /**
   * Successful stop for monitoring channel.
   *
   * Successful stop for monitoring channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `stopSuccessMonitoringChannel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  stopSuccessMonitoringChannel(params: {
    body: SimpleMonitoredChannel
  }): Observable<MonitoredChannel> {

    return this.stopSuccessMonitoringChannel$Response(params).pipe(
      map((r: StrictHttpResponse<MonitoredChannel>) => r.body as MonitoredChannel)
    );
  }

  /**
   * Path part for operation setMonitoredChannelError
   */
  static readonly SetMonitoredChannelErrorPath = '/monitoredchannel/error';

  /**
   * Set monitored channel error.
   *
   * Set monitored channel error and eventually add a message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setMonitoredChannelError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setMonitoredChannelError$Response(params: {
    body: MonitoredChannelError
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetMonitoredChannelErrorPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set monitored channel error.
   *
   * Set monitored channel error and eventually add a message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setMonitoredChannelError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setMonitoredChannelError(params: {
    body: MonitoredChannelError
  }): Observable<void> {

    return this.setMonitoredChannelError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation manageRestartMonitoredChannels
   */
  static readonly ManageRestartMonitoredChannelsPath = '/monitoredchannels/manage-restart';

  /**
   * Manage the restart of the monitored channels if in error status.
   *
   * Manage the restart of the monitored channels if in error status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `manageRestartMonitoredChannels()` instead.
   *
   * This method doesn't expect any request body.
   */
  manageRestartMonitoredChannels$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.ManageRestartMonitoredChannelsPath, 'patch');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Manage the restart of the monitored channels if in error status.
   *
   * Manage the restart of the monitored channels if in error status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `manageRestartMonitoredChannels$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  manageRestartMonitoredChannels(params?: {
  }): Observable<void> {

    return this.manageRestartMonitoredChannels$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queueRedundancy
   */
  static readonly QueueRedundancyPath = '/audiomatchchannel/redundancy/queue';

  /**
   * Queue the monitored channel for redundancy check.
   *
   * Queue the audioamtch channel for redundancy check from a start date to a end date
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueRedundancy()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queueRedundancy$Response(params: {
    body: SubmitRedundancy
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueueRedundancyPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Queue the monitored channel for redundancy check.
   *
   * Queue the audioamtch channel for redundancy check from a start date to a end date
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueRedundancy$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queueRedundancy(params: {
    body: SubmitRedundancy
  }): Observable<void> {

    return this.queueRedundancy$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setRedundancyRunSuccess
   */
  static readonly SetRedundancyRunSuccessPath = '/redundancy-run/success';

  /**
   * Set redundancy run success.
   *
   * Set redundancy run success
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRedundancyRunSuccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setRedundancyRunSuccess$Response(params?: {
    body?: RedundancyRunSuccess
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetRedundancyRunSuccessPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set redundancy run success.
   *
   * Set redundancy run success
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setRedundancyRunSuccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setRedundancyRunSuccess(params?: {
    body?: RedundancyRunSuccess
  }): Observable<void> {

    return this.setRedundancyRunSuccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setRedundancyRunError
   */
  static readonly SetRedundancyRunErrorPath = '/redundancy-run/error';

  /**
   * Set redundancy run error.
   *
   * Set redundancy run error and eventually add a message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRedundancyRunError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setRedundancyRunError$Response(params?: {
    body?: RedundancyRunError
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetRedundancyRunErrorPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set redundancy run error.
   *
   * Set redundancy run error and eventually add a message
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setRedundancyRunError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setRedundancyRunError(params?: {
    body?: RedundancyRunError
  }): Observable<void> {

    return this.setRedundancyRunError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queueShowScheduleAudiomatch
   */
  static readonly QueueShowScheduleAudiomatchPath = '/content/audiomatch/queueShowSchedule';

  /**
   * Queue the content for audio matching.
   *
   * Queue the content for audio feature matching to find show schedule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueShowScheduleAudiomatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queueShowScheduleAudiomatch$Response(params: {
    body: SubmitShowSchedule
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueueShowScheduleAudiomatchPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Queue the content for audio matching.
   *
   * Queue the content for audio feature matching to find show schedule
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueShowScheduleAudiomatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queueShowScheduleAudiomatch(params: {
    body: SubmitShowSchedule
  }): Observable<void> {

    return this.queueShowScheduleAudiomatch$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queueMerge
   */
  static readonly QueueMergePath = '/content/audiomatch/queueMerge';

  /**
   * Queue amids for merge matches operation.
   *
   * Queue amids for merge matches operation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queueMerge()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueMerge$Response(params?: {

    /**
     * Id of the run (if this value is given all other values are ignored)
     */
    run_id?: string;

    /**
     * List of amids
     */
    amids?: Array<Amid>;

    /**
     * Version of the fingerprint
     */
    version?: FingerprintVersion;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    id_server?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udp_port?: MonitoredChannelUdPport;
  }): Observable<StrictHttpResponse<AudioMatchJob>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueueMergePath, 'patch');
    if (params) {
      rb.query('run_id', params.run_id, {});
      rb.query('amids', params.amids, {"style":"pipeDelimited","explode":false});
      rb.query('version', params.version, {});
      rb.query('onairFrom', params.onairFrom, {});
      rb.query('onairTo', params.onairTo, {});
      rb.query('editor', params.editor, {});
      rb.query('channel', params.channel, {});
      rb.query('id_server', params.id_server, {});
      rb.query('udp_port', params.udp_port, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AudioMatchJob>;
      })
    );
  }

  /**
   * Queue amids for merge matches operation.
   *
   * Queue amids for merge matches operation
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queueMerge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queueMerge(params?: {

    /**
     * Id of the run (if this value is given all other values are ignored)
     */
    run_id?: string;

    /**
     * List of amids
     */
    amids?: Array<Amid>;

    /**
     * Version of the fingerprint
     */
    version?: FingerprintVersion;

    /**
     * onair start interval
     */
    onairFrom?: DateTime;

    /**
     * onair end interval
     */
    onairTo?: DateTime;

    /**
     * Content editor
     */
    editor?: Editor;

    /**
     * Channel
     */
    channel?: Channel;

    /**
     * ID of the server that has downloaded the content (for linear content)
     */
    id_server?: OnpremiseServerId;

    /**
     * UDP port from which the content has been downloaded (for linear content)
     */
    udp_port?: MonitoredChannelUdPport;
  }): Observable<AudioMatchJob> {

    return this.queueMerge$Response(params).pipe(
      map((r: StrictHttpResponse<AudioMatchJob>) => r.body as AudioMatchJob)
    );
  }

  /**
   * Path part for operation setMergeSuccess
   */
  static readonly SetMergeSuccessPath = '/content/audiomatch/merge/success';

  /**
   * Set merge success.
   *
   * Set merge success
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setMergeSuccess()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setMergeSuccess$Response(params: {
    body: MergeSuccess
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetMergeSuccessPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set merge success.
   *
   * Set merge success
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setMergeSuccess$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setMergeSuccess(params: {
    body: MergeSuccess
  }): Observable<void> {

    return this.setMergeSuccess$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setMergeError
   */
  static readonly SetMergeErrorPath = '/content/audiomatch/merge/error';

  /**
   * Set merge error.
   *
   * Set merge error
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setMergeError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setMergeError$Response(params: {
    body: MergeError
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SetMergeErrorPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Set merge error.
   *
   * Set merge error
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setMergeError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setMergeError(params: {
    body: MergeError
  }): Observable<void> {

    return this.setMergeError$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queuePartialFullAudiomatch
   */
  static readonly QueuePartialFullAudiomatchPath = '/content/audiomatch/queuePartialFull';

  /**
   * Queue the content for audio matching.
   *
   * Queue the content for audio feature matching to find partial-full relationship
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queuePartialFullAudiomatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  queuePartialFullAudiomatch$Response(params: {

    /**
     * json string with filters for the queries
     */
    queries_filters?: string;

    /**
     * json string with filters for the references
     */
    references_filters?: string;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;
  }): Observable<StrictHttpResponse<JobId>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueuePartialFullAudiomatchPath, 'patch');
    if (params) {
      rb.query('queries_filters', params.queries_filters, {});
      rb.query('references_filters', params.references_filters, {});
      rb.query('version', params.version, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobId>;
      })
    );
  }

  /**
   * Queue the content for audio matching.
   *
   * Queue the content for audio feature matching to find partial-full relationship
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queuePartialFullAudiomatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queuePartialFullAudiomatch(params: {

    /**
     * json string with filters for the queries
     */
    queries_filters?: string;

    /**
     * json string with filters for the references
     */
    references_filters?: string;

    /**
     * Fingerprint algorithm version
     */
    version: FingerprintVersion;
  }): Observable<JobId> {

    return this.queuePartialFullAudiomatch$Response(params).pipe(
      map((r: StrictHttpResponse<JobId>) => r.body as JobId)
    );
  }

  /**
   * Path part for operation sampleAudiomatchMetrics
   */
  static readonly SampleAudiomatchMetricsPath = '/metrics/sample';

  /**
   * Sample all audiomatch metrics.
   *
   * Sample all audiomatch metrics
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sampleAudiomatchMetrics()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sampleAudiomatchMetrics$Response(params: {
    body: SampleMetric
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.SampleAudiomatchMetricsPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sample all audiomatch metrics.
   *
   * Sample all audiomatch metrics
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sampleAudiomatchMetrics$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sampleAudiomatchMetrics(params: {
    body: SampleMetric
  }): Observable<void> {

    return this.sampleAudiomatchMetrics$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation queuePolling
   */
  static readonly QueuePollingPath = '/polling/queue';

  /**
   * Queue job for polling.
   *
   * Queue job for polling the Libreria dei Contenuti
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queuePolling()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queuePolling$Response(params: {

    /**
     * start date
     */
    start_date: DateTime;

    /**
     * end date
     */
    end_date: DateTime;

    /**
     * max number of inserted contents
     */
    max_num?: MaxNum;

    /**
     * start date for query advertisements (only if include_adv is True). If not given start_date is used
     */
    start_date_adv?: DateTime;

    /**
     * end date for query advertisements (only if include_adv is True). If not given end_date is used
     */
    end_date_adv?: DateTime;

    /**
     * Include advertisements in polling procedure
     */
    include_adv?: boolean;
    body?: AutoPolicy
  }): Observable<StrictHttpResponse<JobId>> {

    const rb = new RequestBuilder(this.rootUrl, OperateService.QueuePollingPath, 'patch');
    if (params) {
      rb.query('start_date', params.start_date, {});
      rb.query('end_date', params.end_date, {});
      rb.query('max_num', params.max_num, {});
      rb.query('start_date_adv', params.start_date_adv, {});
      rb.query('end_date_adv', params.end_date_adv, {});
      rb.query('include_adv', params.include_adv, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<JobId>;
      })
    );
  }

  /**
   * Queue job for polling.
   *
   * Queue job for polling the Libreria dei Contenuti
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `queuePolling$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  queuePolling(params: {

    /**
     * start date
     */
    start_date: DateTime;

    /**
     * end date
     */
    end_date: DateTime;

    /**
     * max number of inserted contents
     */
    max_num?: MaxNum;

    /**
     * start date for query advertisements (only if include_adv is True). If not given start_date is used
     */
    start_date_adv?: DateTime;

    /**
     * end date for query advertisements (only if include_adv is True). If not given end_date is used
     */
    end_date_adv?: DateTime;

    /**
     * Include advertisements in polling procedure
     */
    include_adv?: boolean;
    body?: AutoPolicy
  }): Observable<JobId> {

    return this.queuePolling$Response(params).pipe(
      map((r: StrictHttpResponse<JobId>) => r.body as JobId)
    );
  }

}
