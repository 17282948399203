import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../environments/environment';


export interface DialogData {
  fingerprintVersion: number,
  uploadOption: string,
  segmentDuration: string,
  protocol: string
}

@Component({
  selector: 'app-monitoredchannel-configuration-settings',
  templateUrl: './monitoredchannel-configuration-settings.component.html',
  styleUrls: ['./monitoredchannel-configuration-settings.component.css']
})
export class MonitoredchannelConfigurationSettingsComponent implements OnInit {

  uploadOptions = [
    {
      'option': '7',
      'display': 'Fingerprint + Audio + Video'
    },
    {
      'option': '6',
      'display': 'Fingerprint + Audio'
    },
    {
      'option': '3',
      'display': 'Fingerprint'
    }
  ];

  segmentDurations = ['300', '600', '900', '1200', '1800']

  protocols = ['udp', 'rtp']

  constructor(
    public dialogRef: MatDialogRef<MonitoredchannelConfigurationSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
    this.data.fingerprintVersion = environment.defaultFingerprintVersion
  }

}
