import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import S3 from 'aws-sdk/clients/s3';

@Injectable({
  providedIn: 'root'
})
export class ContentDownloadService {

  constructor() { }

  async videoDownload(video_uri: string): Promise<string> {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region,
      }
    );
    const params = {
      Bucket: environment.contentBucket,
      Key: video_uri
    };
    const url = await bucket.getSignedUrlPromise('getObject', params)
    return url
  }

  videoDownloadSync(video_uri: string): string {
    const bucket = new S3(
      {
        accessKeyId: environment.accessKeyId,
        secretAccessKey: environment.secretAccessKey,
        region: environment.region,
      }
    );
    const params = {
      Bucket: environment.contentBucket,
      Key: video_uri
    };
    const url = bucket.getSignedUrl('getObject', params)
    return url
  }

}



