import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { RetrieveService } from '../api/services/retrieve.service';
import { Content } from '../api/models/content';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Match } from '../api/models/match';
import { CountGroup } from '../api/models/count-group';
import { ContentDownloadService } from '../content-download.service'
import { FormControl } from '@angular/forms';
import { Type } from '../api/models/type'
import moment from 'moment';
import { environment } from '../../environments/environment';
import { S3IntelligentTieringAccessTier } from '../api/models/s-3-intelligent-tiering-access-tier';
import { S3StorageClass } from '../api/models/s-3-storage-class';

export interface MatchesSearchParams {
  type?: Type,
  publishedFrom?: string;
  publishedTo?: string;
  onairFrom?: string;
  onairTo?: string;
  minDuration?: number;
  channel?: string;
}

class Node {
  startOndemand: number;
  endOndemand: number;
  startLinear: number;
  endLinear: number;

  constructor(startOndemand: number, endOndemand: number, startLinear: number, endLinear: number) {
    this.startOndemand = startOndemand;
    this.endOndemand = endOndemand;
    this.startLinear = startLinear;
    this.endLinear = endLinear;
  }

  ondemandDuration() {
    return this.endOndemand - this.startOndemand;
  }

  linearDuration() {
    return this.endLinear - this.startLinear;
  }

  isEqual(node: Node) {
    return (node.startLinear == this.startLinear && node.endLinear == this.endLinear &&
      node.startOndemand == this.startOndemand && node.endOndemand == this.endOndemand)
  }
}

interface nodeSearchResult {
  nextIdx: number;
  nextNode: Node;
}

interface nodeGroupResult {
  idxGroup: Array<Array<number>>;
  groups: Array<Node>;
}

interface MatchTime {
  startTimeLinear: string;
  endTimeLinear: string;
  startTimeOndemand: string;
  endTimeOndemand: string;
  isMain: boolean;
}

interface matchGroupByDate {
  [date: string]: Array<MatchTime>
}

function groupMatchByDate(groups: Array<Node>, minGroupDuration = 30, mainGroup: Array<Node> = null) {
  let results: matchGroupByDate = {};
  let allDates: Array<string> = [];

  groups.forEach((group) => {
    if (group.ondemandDuration() > minGroupDuration) {
      let isMainGroup = false;
      if (mainGroup != null) {
        mainGroup.forEach((mG) => {
          if (mG.isEqual(group)) {
            isMainGroup = true
          }
        });
      }

      let isOverlap = false
      if (!isMainGroup && mainGroup != null) {
        mainGroup.forEach((mG) => {
          if (isOverlapped(mG, group)) {
            isOverlap = true
          }
        });
      }
      if (mainGroup == null || isMainGroup || !isOverlap) {
        let onairDate = moment.unix(group.startLinear).format("DD-MM-YYYY");
        if (!allDates.includes(onairDate)) {
          results[onairDate] = [];
          allDates.push(onairDate);
        }
        let item: MatchTime = {
          startTimeLinear: moment.unix(group.startLinear).format('HH:mm:ss'),
          endTimeLinear: moment.unix(group.endLinear).format('HH:mm:ss'),
          startTimeOndemand: moment.utc(group.startOndemand * 1000).format('HH:mm:ss'),
          endTimeOndemand: moment.utc(group.endOndemand * 1000).format('HH:mm:ss'),
          isMain: isMainGroup
        }
        results[onairDate].push(item)
      }
    }
  });

  return results
}

function searchNextNode(node: Node, nodes: Array<Node>, ondemandTh: number, linearTh: number, idxGrouped: Array<number>) {
  let bestNode: Node = null;
  let bestNodeIdx: number = -1

  nodes.forEach((n, i) => {
    if (idxGrouped.includes(i)) {
      if (Math.abs(n.startLinear - node.endLinear) < linearTh &&
        (n.startOndemand - node.endOndemand) > 0 && (n.startOndemand - node.endOndemand) < ondemandTh
        && n.startLinear > node.startLinear) {
        if (bestNode == null || n.ondemandDuration() > bestNode.ondemandDuration()) {
          bestNode = n;
          bestNodeIdx = i;
        }
      }
    }
  });
  let result: nodeSearchResult = {
    nextIdx: bestNodeIdx,
    nextNode: bestNode
  }
  return result
}

function isOverlapped(node1: Node, node2: Node) {
  if ((node1.startLinear > node2.startLinear && node1.endLinear < node2.endLinear) || (node2.startLinear > node1.startLinear && node2.endLinear < node1.endLinear)) {
    return true
  }
  return false
}

function removeItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

function groupNodes(nodes: Array<Node>, idxToGroup: Array<number>, ondemandTh: number, linearTh: number) {
  let groups: Array<Node> = [];
  let idxGroup: Array<Array<number>> = [];

  while (idxToGroup.length > 0) {
    let nodeIdx: number = idxToGroup[0];
    let currentGroup = nodes[nodeIdx]
    let currentIdx = [nodeIdx]
    idxToGroup = removeItemOnce(idxToGroup, nodeIdx);

    while (nodeIdx != -1) {

      let searchResult = searchNextNode(currentGroup, nodes, ondemandTh, linearTh, idxToGroup);
      nodeIdx = searchResult.nextIdx;
      let nextNode = searchResult.nextNode;

      if (nodeIdx != -1) {
        currentIdx.push(nodeIdx)
        idxToGroup = removeItemOnce(idxToGroup, nodeIdx);
        currentGroup.endOndemand = nextNode.endOndemand;
        currentGroup.endLinear = nextNode.endLinear;
      } else {
        groups.push(currentGroup);
        idxGroup.push(currentIdx)
      }
    }
  }



  let results: nodeGroupResult = {
    idxGroup: idxGroup,
    groups: groups
  }
  return results
}

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit, AfterViewInit {

  @ViewChild('mainVideo')
  private videoPlayer: ElementRef;

  fingerprintVersions = environment.fingerprintVersions;
  defaultFingerprintVersion = environment.defaultFingerprintVersion;

  content: Content = null;
  isContentLoaded: boolean = false;

  matches: Match[] = null;
  areMatchesLoaded: boolean = false;

  countMatches: number;
  countOndemandMatches: number;
  countLinearMatches: any[];
  areMatchesCountLoaded: boolean = false;

  linearMatches$: Observable<Match[]>;

  video_url: string;

  show = false;

  minMatchDuration = 0;
  selectedMatches = 0;

  indexing_keys = ['indexing_error', 'indexing_status', 'indexing_lastUpdate', 'indexing_job', 'index_uri', 'index_pts_uri', 'start_idx', 'end_idx', 'indexing_elapsed']

  types = ['ondemand', 'linear']
  hiddenLink: boolean = false
  matchesSearchParams: MatchesSearchParams = {}

  typeFilter = new FormControl()
  durationFilter = new FormControl()

  /* Datepickers helpers */
  onairFrom = new FormControl();
  onairTo = new FormControl();
  publishedFrom = new FormControl();
  publishedTo = new FormControl();
  channel = new FormControl();

  fingerprintVersion = new FormControl()
  currentAmid = ''

  videoOnairDate = new FormControl();
  minVideoDate = '';
  maxVideoDate = '';
  startOnairDate = null;
  startSeconds = null;

  groupedMatches = {};
  minGroupDuration = 40;

  videoLoadedData: boolean = false;

  autoLoadMedia: boolean = false;
  mediaPresent: boolean = false;


  view: [number, number] = [700, 400];
  level1ClassProbabilitiesData: any[];
  level2ClassProbabilitiesData: any[];

  // ngx-charts settings
  showXAxis = true;
  showYAxis = true;
  showLegend = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  xAxisLabel = 'Label';
  yAxisLabel = 'Probability';
  gradient = false;

  constructor(
    private retrieve: RetrieveService,
    private download: ContentDownloadService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  checkVersion(version): boolean {
    var splitted = version.split("v");
    return parseInt(splitted[1]) == this.defaultFingerprintVersion
  }
  setVideoDataLoaded(): void {
    this.videoLoadedData = true;
    if (this.content.type == 'linear' && this.startOnairDate) {
      let currentTime = moment(this.startOnairDate).diff(moment(this.content.onair), 'seconds');
      let currentOnair = moment(this.content.onair).add(currentTime, 'seconds')
      this.videoOnairDate.setValue(moment(currentOnair).toISOString());
    }
    if (this.content.type != 'linear' && this.startSeconds) {
      this.videoPlayer.nativeElement.currentTime = this.startSeconds;
    }
  }

  setVideoCurrentTime(): void {
    if (this.content.type == 'linear' && this.videoLoadedData && this.isContentLoaded) {
      let currentTime = moment(this.videoOnairDate.value).diff(moment(this.content.onair), 'seconds');
      this.videoPlayer.nativeElement.currentTime = currentTime;
    }
  }

  setCurrentTime(data) {
    if (this.isContentLoaded) {
      if (this.content.type == 'linear') {
        let currentOnair = moment(this.content.onair).add(data.target.currentTime, 'seconds')
        this.videoOnairDate.setValue(moment(currentOnair).toISOString(), { emitEvent: false });
      }
    }
  }

  ngOnInit(): void {

    this.isContentLoaded = false;
    this.groupedMatches = {};

    combineLatest([this.route.paramMap, this.route.queryParams]).subscribe(
      (results) => {
        let pathParam: ParamMap = results[0];
        let params = results[1];

        // Set current amid variable

        this.currentAmid = pathParam.get('amid');

        this.startOnairDate = params['startOnairDate'] ? moment(params['startOnairDate']).toISOString() : null;
        this.startSeconds = params['startSeconds'] ? parseFloat(params['startSeconds']) : null;

        // Retrieve content and store it in local variable

        this.retrieve.getContent({ amid: this.currentAmid }).subscribe(
          (content: Content) => {
            this.content = content;
            if (content.type == 'linear') {
              this.videoOnairDate.setValue(params['videoOnairDate'] ? moment(params['videoOnairDate']).toISOString() : moment(content.onair).toISOString(), { emitEvent: false });
              this.minVideoDate = moment(content.onair).toISOString()
              this.maxVideoDate = (moment(content.onair).add(content.download.duration, 'seconds')).toISOString()
            } else if (content.type == 'adv') {
              this.minGroupDuration = 0;
            }

            let loadVideo = false;
            let loadAudio = false;
            let videoPresent = false;
            let audioPresent = false;

            if (content.download.videoUri && content.download.videoUri.endsWith('.mp4')) {

              videoPresent = true;
              if (!('video_storage_class' in content.download) || content.download.video_storage_class == S3StorageClass.Standard ||
                (content.download.video_storage_class == S3StorageClass.IntelligentTiering && content.download.video_intelligent_tiering_access_tier == S3IntelligentTieringAccessTier.Frequent)) {
                loadVideo = true;
              }
            } else if (content.download.audioUri) {
              audioPresent = true;
              if (!('audio_storage_class' in content.download) || content.download.audio_storage_class == S3StorageClass.Standard ||
                (content.download.audio_storage_class == S3StorageClass.IntelligentTiering && content.download.audio_intelligent_tiering_access_tier == S3IntelligentTieringAccessTier.Frequent)) {
                loadAudio = true;
              }
            }

            if (loadVideo) {
              this.downloadMedia(content.download.videoUri)
              this.autoLoadMedia = true;
              this.mediaPresent = true;
            } else if (loadAudio) {
              this.downloadMedia(content.download.audioUri)
              this.autoLoadMedia = true;
              this.mediaPresent = true;
            } else if (audioPresent || videoPresent) {
              this.mediaPresent = true;
            }

            this.isContentLoaded = true;
            this.groupMatches();

            if (this.content.genre && this.content.genre.status=='completed'){
              let transformedDataLevel1 = this.transformData(this.content.genre.level1ClassProbabilities)
              const sortedDataLevel1 = transformedDataLevel1.sort((a, b) => b.value - a.value);
              this.level1ClassProbabilitiesData = sortedDataLevel1.slice(0, Math.min(sortedDataLevel1.length, 8));

              let transformedDataLevel2 = this.transformData(this.content.genre.level2ClassProbabilities)
              const sortedDataLevel2 = transformedDataLevel2.sort((a, b) => b.value - a.value);
              this.level2ClassProbabilitiesData = sortedDataLevel2.slice(0, Math.min(sortedDataLevel2.length, 8));
            }
          }
        )

        this.fingerprintVersion.setValue(params['version'] ? parseInt(params['version']) : this.defaultFingerprintVersion, { emitEvent: false });

        // Count matches
        this.areMatchesCountLoaded = false;
        let countMatches$ = this.retrieve.countMatches({ amid: this.currentAmid, version: this.fingerprintVersion.value });
        let countOndemandMatches$ = this.retrieve.countMatches({ amid: this.currentAmid, version: this.fingerprintVersion.value, type: Type.Ondemand });
        let countLinearMatches$ = this.retrieve.countMatches({ amid: this.currentAmid, version: this.fingerprintVersion.value, type: Type.Linear, groupBy: 'onair' });

        combineLatest([countMatches$, countOndemandMatches$, countLinearMatches$]).subscribe(
          (results) => {
            this.countMatches = results[0];
            this.countOndemandMatches = results[1];
            this.countLinearMatches = results[2] as unknown as any[];
            this.areMatchesCountLoaded = true;
          }
        )


        this.matchesSearchParams['minDuration'] = 0
        this.loadMatches();
      }
    )
  }

  
  transformData(originalData) {
    return originalData.map(item => ({
      name: item.label,
      value: item.probability
    }));
  }

  downloadMedia(uri) {
    this.download.videoDownload(uri).then(
      (url) => {
        this.video_url = url;
      }
    )
  }

  loadMedia() {
    if (confirm("Are you sure to load media?")) {

      if (this.content.download.videoUri && this.content.download.videoUri.endsWith('.mp4')) {
        this.downloadMedia(this.content.download.videoUri)
      } else if (this.content.download.audioUri) {
        this.downloadMedia(this.content.download.audioUri)
      }

      this.autoLoadMedia = true;
    }
  }

  ngAfterViewInit() {
    this.typeFilter.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['type'] = value
        if (value == 'ondemand') {
          this.onairFrom.disable()
          this.onairTo.disable()
          this.channel.disable()
        } else {
          this.onairFrom.enable()
          this.onairTo.enable()
          this.channel.enable()
        }

        if (value == 'linear') {
          this.publishedFrom.disable()
          this.publishedTo.disable()
        } else {
          this.publishedFrom.enable()
          this.publishedTo.enable()
        }
        this.loadMatches()
      });

    this.durationFilter.valueChanges.subscribe(
      value => {
        this.matchesSearchParams['minDuration'] = value
      });

    this.onairFrom.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['onairFrom'] = value ? moment(value).set({ second: 0, millisecond: 0 }).toISOString() : null;
        if (value && !this.onairTo.value) {
          this.onairTo.setValue(moment(value).add(1, 'd').toISOString())
        }
        this.loadMatches()
      });

    this.onairTo.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['onairTo'] = value ? moment(value).set({ second: 0, millisecond: 0 }).toISOString() : null;
        this.loadMatches()
      });

    this.channel.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.matchesSearchParams['channel'] = value ? value : null;
      this.loadMatches()
    });

    this.publishedFrom.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['publishedFrom'] = value ? moment(value).set({ second: 0, millisecond: 0 }).toISOString() : null;
        if (value) {
          this.publishedTo.setValue(moment(value).add(1, 'd').toISOString())
        }
        this.loadMatches()
      });

    this.publishedTo.valueChanges
      .subscribe(value => {
        this.matchesSearchParams['publishedTo'] = value ? moment(value).set({ second: 0, millisecond: 0 }).toISOString() : null;
        this.loadMatches()
      });

    this.fingerprintVersion.valueChanges.pipe(
    ).subscribe(value => {
      this.router.navigate(['content/' + this.currentAmid], {
        queryParams: {
          version: value,
        },
        queryParamsHandling: 'merge'
      });
    });

    this.videoOnairDate.valueChanges.pipe(
    ).subscribe(value => {
      this.setVideoCurrentTime()
      // this.router.navigate(['content/' + this.currentAmid], {
      //   queryParams: {
      //     videoOnairDate: moment(value).toISOString(),
      //   },
      //   queryParamsHandling: 'merge'
      // });
    });
  }

  loadMatches() {

    this.areMatchesLoaded = false;
    this.retrieve.getMatches({ amid: this.currentAmid, version: this.fingerprintVersion.value, ...this.matchesSearchParams }).subscribe(
      (matches: Match[]) => {
        this.matches = matches;
        this.areMatchesLoaded = true;
        this.onInputChange()
      }
    )
  }

  groupMatches() {
    this.linearMatches$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.retrieve.getMatches({ amid: params.get('amid'), version: this.fingerprintVersion.value, type: Type.Linear })
      )
    );
    this.linearMatches$.subscribe((results) => {


      let content: Content = this.content;
      let allMatches: Array<Match> = results;

      let matchesByChannel = {}
      allMatches.forEach((element) => {
        if (!(element.channel in matchesByChannel)) {
          matchesByChannel[element.channel] = []
        }
        matchesByChannel[element.channel].push(element)
      })

      for (const [key, value] of Object.entries(matchesByChannel)) {

        let channel = key;
        let matches = value as Array<Match>;


        if (matches.length == 0) {
          return
        }
        matches.sort(function (a, b) {
          var keyA = moment(a.onair).add(a.otheroffset, 'seconds');
          var keyB = moment(b.onair).add(b.otheroffset, 'seconds');
          // Compare the 2 dates
          if (keyA < keyB) return -1;
          if (keyA > keyB) return 1;
          return 0;
        });

        let nodes: Array<Node> = [];
        matches.forEach(element => {
          nodes.push(
            new Node(element.selfoffset, element.selfoffset + element.duration,
              moment(element.onair).add(element.otheroffset, 'seconds').unix(), moment(element.onair).add(element.otheroffset + element.duration, 'seconds').unix())
          )
        });

        let ondemandTh = Math.max(Math.min(content.download.duration / 16, 120), 10);
        let linearTh = 25 * 60;
        let idxToGroup = Array.from({ length: nodes.length }, (x, i) => i);
        let groupResult = groupNodes(nodes, idxToGroup, ondemandTh, linearTh);

        let idxGroup = groupResult.idxGroup;
        let groups = groupResult.groups;

        let foundFullMatch: boolean = false;
        let fullMatchIdx: number;
        let mainGroup: Array<Node> = [];
        let mainGroupIdxs: Array<number> = [];
        groups.forEach((element, idx) => {
          if (element.ondemandDuration() / content.download.duration > 0.85) {
            foundFullMatch = true;
            fullMatchIdx = idx;
            mainGroup.push(element);
            mainGroupIdxs.push(...idxGroup[idx])
          }
        });

        if (foundFullMatch) {
          let nodes: Array<Node> = [];
          matches.forEach(element => {
            nodes.push(
              new Node(element.selfoffset, element.selfoffset + element.duration,
                moment(element.onair).add(element.otheroffset, 'seconds').unix(), moment(element.onair).add(element.otheroffset + element.duration, 'seconds').unix())
            )
          });

          idxToGroup = Array.from({ length: nodes.length }, (x, i) => i);
          mainGroupIdxs.forEach((element) => {
            idxToGroup = removeItemOnce(idxToGroup, element);
          });
          ondemandTh = 10;
          groupResult = groupNodes(nodes, idxToGroup, ondemandTh, linearTh);
          groups = groupResult.groups;
          idxGroup = groupResult.idxGroup;
          groups.push(...mainGroup);
          idxGroup.push(mainGroupIdxs);
        }
        let finalResult = groupMatchByDate(groups, this.minGroupDuration, mainGroup)
        if (Object.keys(finalResult).length > 0) {
          this.groupedMatches[channel] = finalResult;
        }
      }
    })
  }

  onInputChange() {
    this.selectedMatches = 0;
    this.hiddenLink = true;
    if (this.areMatchesLoaded) {
      this.matches.forEach(match => {
        if (match.duration > this.minMatchDuration) {
          this.selectedMatches++;
        }
      })
      this.hiddenLink = this.selectedMatches == 0
    }
  }

  checkMatch(i: number, match: Match) {
    if (match.duration > this.minMatchDuration) {
      return true
    }
    return false
  }

  getMatchVisualizerEnabled() {
    return this.selectedMatches > 0
  }

  goMatchVisualizer(amid: string) {
    this.router.navigate(['/matchesvisualizer', amid], { queryParams: { version: this.fingerprintVersion.value, ...this.matchesSearchParams } });
  }

  sort_runs(runs) {
    // Create items array
    var items = Object.keys(runs).map(function (key) {
      return [key, runs[key]];
    });

    items.sort((first, second) => {
      var diff: number = moment.duration(moment(first[1]['lastUpdate']).diff(moment(second[1]['lastUpdate']))).asSeconds()
      return diff
    });

    return items
  }

  checkDuration(g) {
    if (moment(g.endTimeOndemand, 'HH:mm:ss').diff(moment(g.startTimeOndemand, 'HH:mm:ss')) > this.minGroupDuration) {
      return true;
    }
    return false;
  }

  onGroupDurationChange() {
    this.groupedMatches = {};
    this.groupMatches();
  }

}
