import { AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { MonitoredChannelsListDataSource } from './monitoredchannels-list-datasource'
import { MasterMonitoredChannelsListDataSource } from './master-monitoredchannels-list-datasource'
import { RetrieveService } from '../api/services/retrieve.service';
import { OperateService } from '../api/services/operate.service';
import { DeleteService } from '../api/services/delete.service';
import { MonitoredChannel } from '../api/models/monitored-channel';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, RouterLinkWithHref } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { MonitoredChannelStatus } from '../api/models/monitored-channel-status';
import { SimpleMonitoredChannel } from '../api/models/simple-monitored-channel';
import { AudiomatchChannel } from '../api/models/audiomatch-channel';
import { MonitoredchannelConfigurationSettingsComponent, DialogData } from '../monitoredchannel-configuration-settings/monitoredchannel-configuration-settings.component'
import { combineLatest, Observable } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ShowScheduleStatus } from '../api/models/show-schedule-status';
import { OnpremiseServer } from '../api/models/onpremise-server';
import { MonitoredChannelProtocol } from '../api/models/monitored-channel-protocol';
import { environment } from 'src/environments/environment';


interface MonitoredChannelStatusView {
  value: string;
  viewValue: string;
}

export interface SearchParams {
  amchid?: string,
  channel?: string,
  editor?: string;
  id_server?: string;
  server_location?: string;
  udp_port?: number;
  status?: MonitoredChannelStatus;
  protocol?: MonitoredChannelProtocol;
  insertedFrom?: string;
  insertedTo?: string;
  statusGraphData?: boolean;
  statusGraphDateFrom?: string;
  statusGraphDateTo?: string;
  missing_intervals_duration_th?: number,
  excludeMasters?: boolean;
  limit?: number;
  offset?: number;
  sort?: 'channel' | 'editor' | 'id_server' | 'udp_port' | 'status' | 'inserted';
  order?: 'asc' | 'desc';
}

export interface MasterSearchParams {
  amchid?: string,
  channel?: string,
  editor?: string;
  udp_port?: number;
  showscheduleStatus?: ShowScheduleStatus,
  insertedFrom?: string;
  insertedTo?: string;
  statusGraphData?: boolean;
  statusGraphDateFrom?: string;
  statusGraphDateTo?: string;
  sourceGraphData?: boolean;
  sourceGraphDateFrom?: string;
  sourceGraphDateTo?: string;
  fpversion?: number,
  limit?: number;
  offset?: number;
  sort?: 'channel' | 'editor' | 'id_server' | 'udp_port' | 'status' | 'inserted';
  order?: 'asc' | 'desc';
}

@Component({
  selector: 'app-monitoredchannels-list',
  templateUrl: './monitoredchannels-list.component.html',
  styleUrls: ['./monitoredchannels-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MonitoredchannelsListComponent implements OnInit, AfterViewInit {
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  // @ViewChild('paginator') paginator: MatPaginator;
  // @ViewChild('sort') sort: MatSort;
  // @ViewChild('masterPaginator') masterPaginator: MatPaginator;
  // @ViewChild('masterSort') masterSort: MatSort;
  // @ViewChild(MatTable) table: MatTable<MonitoredChannel>;

  onpremiseServers: OnpremiseServer[];
  onpremiseServersLocations;
  onpremiseServersLoaded: boolean = false;

  ranges: any = {
    'Last 12 hours': [moment().subtract(12, 'hours'), moment()],
    'Last 24 hours': [moment().subtract(24, 'hours'), moment()],
    'Last 3 days': [moment().subtract(3, 'days'), moment()],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 15 Days': [moment().subtract(14, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
  }

  /* Pagination */
  pageIndex: number;
  pageSize: number;

  /* Sorting */
  sortActive: string;
  sortDirection: 'asc' | 'desc';


  /* Pagination */
  masterPageIndex: number;
  masterPageSize: number;

  /* Sorting */
  masterSortActive: string;
  masterSortDirection: 'asc' | 'desc';

  /** query parameters */
  searchParams: SearchParams = {
    excludeMasters: true,
    missing_intervals_duration_th: 5
  };
  masterSearchParams: MasterSearchParams = {
    fpversion: environment.defaultFingerprintVersion
  }

  /** Table data source */
  dataSource: MonitoredChannelsListDataSource;
  masterDataSource: MasterMonitoredChannelsListDataSource;

  /**  Filters */
  amchidFilter = new FormControl();
  channelFilter = new FormControl();
  editorFilter = new FormControl();
  idServerFilter = new FormControl();
  serverLocationFilter = new FormControl();
  udpPortFilter = new FormControl();
  protocols = ['udp', 'rtp'];
  protocolFilter = new FormControl();
  showStatusGraph = new FormControl();
  statusGraphDateRange = new FormControl({
    endDate: moment(),
    startDate: moment().subtract(24, 'hours')
  });

  showSourceGraph = new FormControl();
  sourceGraphDateRange = new FormControl({
    endDate: moment(),
    startDate: moment().subtract(24, 'hours')
  });

  tablesToShow = new FormControl();
  tablesToShowValues = [
    { value: 'both', viewValue: 'Monitored Channels + Masters' },
    { value: 'monitored', viewValue: 'Monitored Channels' },
    { value: 'master', viewValue: 'Masters' }
  ]

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['actions', 'amchid', 'channel', 'editor', 'id_server', 'udp_port', 'status', 'protocol', 'inserted', 'details', 'contents', 'delete'];
  masterDisplayedColumns = ['amchid', 'channel', 'editor', 'inserted', 'showschedule_status', 'details', 'contents'];
  expandedElement: MonitoredChannel | null;

  /* Datepickers helpers */
  insertedFrom = new FormControl();
  insertedTo = new FormControl();

  /* Onpremise server status */
  monitoredChannelStatuses: MonitoredChannelStatusView[] = [
    { value: 'none', viewValue: 'Empty' },
    { value: 'pending', viewValue: 'Pending' },
    { value: 'running', viewValue: 'Runnning' },
    { value: 'error', viewValue: 'Error' },
    { value: 'stopping', viewValue: 'Stopping' },
    { value: 'stopped', viewValue: 'Stopped' },
  ];

  monitoredChannelStatus = new FormControl();

  showscheduleStatuses = [
    { value: 'off', viewValue: 'Off' },
    { value: 'on', viewValue: 'On' },
  ];
  showscheduleStatusFilter = new FormControl();

  audiomatchChannels$: Observable<AudiomatchChannel[]>

  constructor(
    private retrieve: RetrieveService,
    private operate: OperateService,
    private deleteService: DeleteService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  };

  ngOnInit() {

    this.audiomatchChannels$ = this.retrieve.getAudiomatchChannels({ limit: -1 });

    this.onpremiseServersLoaded = false;
    this.retrieve.getOnpremiseServersList({ limit: -1 }).subscribe(
      (servers) => {
        this.onpremiseServers = [];
        this.onpremiseServersLocations = []

        servers.forEach((server) => {
          if (!(server.id == 'master')) {
            this.onpremiseServers.push(server)
          }

          if (!(this.onpremiseServersLocations.find(e => e.location === server.location)) && server.id != 'master') {
            this.onpremiseServersLocations.push(
              {
                'displayLocation': server.location.split("-").join(" "),
                'location': server.location
              }
            )
          }
        })

        this.onpremiseServersLoaded = true;

      }
    )

    this.dataSource = new MonitoredChannelsListDataSource(this.retrieve);
    this.masterDataSource = new MasterMonitoredChannelsListDataSource(this.retrieve);

    /* Read query parameters from URL */
    this.route.queryParams.subscribe(params => {
      this.monitoredChannelStatus.setValue(params['status'], { emitEvent: false });
      this.showscheduleStatusFilter.setValue(params['showscheduleStatus'], { emitEvent: false });
      this.amchidFilter.setValue(params['amchid'], { emitEvent: false });
      this.channelFilter.setValue(params['channel'], { emitEvent: false });
      this.editorFilter.setValue(params['editor'], { emitEvent: false });
      this.idServerFilter.setValue(params['id_server'], { emitEvent: false });
      this.serverLocationFilter.setValue(params['server_location'], { emitEvent: false });
      this.udpPortFilter.setValue(params['udp_port'], { emitEvent: false });
      this.protocolFilter.setValue(params['protocol'], { emitEvent: false });
      this.insertedFrom.setValue(params['insertedFrom'] ? moment(params['insertedFrom']) : null, { emitEvent: false });
      this.insertedTo.setValue(params['insertedTo'] ? moment(params['insertedTo']) : null, { emitEvent: false });
      this.statusGraphDateRange.setValue({
        startDate: params['statusGraphDateFrom'] ? moment(params['statusGraphDateFrom']) : moment().subtract(24, 'hours'),
        endDate: params['statusGraphDateTo'] ? moment(params['statusGraphDateTo']) : moment(),
      }, { emitEvent: false });
      this.showStatusGraph.setValue(params['statusGraphData'] ? params['statusGraphData'] === 'true' : false, { emitEvent: false });
      this.sourceGraphDateRange.setValue({
        startDate: params['sourceGraphDateFrom'] ? moment(params['sourceGraphDateFrom']) : moment().subtract(24, 'hours'),
        endDate: params['sourceGraphDateTo'] ? moment(params['sourceGraphDateTo']) : moment(),
      }, { emitEvent: false });
      this.showSourceGraph.setValue(params['sourceGraphData'] ? params['sourceGraphData'] === 'true' : false, { emitEvent: false });
      this.pageIndex = Number(params['pageIndex']) || 0;
      this.pageSize = Number(params['pageSize']) || 10;
      this.sortDirection = params['sortDirection'] || 'desc';
      this.sortActive = params['sortActive'] || 'inserted';

      this.masterPageIndex = Number(params['masterPageIndex']) || 0;
      this.masterPageSize = Number(params['masterPageSize']) || 10;
      this.masterSortDirection = params['masterSortDirection'] || 'desc';
      this.masterSortActive = params['masterSortActive'] || 'inserted';

      this.tablesToShow.setValue(params['tables'] ? params['tables'] : 'both', { emitEvent: false });

      if (!(this.showStatusGraph.value)) {
        this.statusGraphDateRange.disable();
      } else {
        this.statusGraphDateRange.enable();
      }

      if (this.tablesToShow.value == 'monitored') {
        this.showSourceGraph.disable();
        this.sourceGraphDateRange.disable();
      } else {
        this.showSourceGraph.enable();
        if (!(this.showSourceGraph.value)) {
          this.sourceGraphDateRange.disable();
        } else {
          this.sourceGraphDateRange.enable();
        }
      }

      this.loadMonitoredChannelsPage();
    });

  }

  ngAfterViewInit() {

    this.amchidFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          amchid: value,
          pageIndex: 0,
          masterPageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.channelFilter.valueChanges.pipe(
      debounceTime(700),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          channel: value,
          pageIndex: 0,
          masterPageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.editorFilter.valueChanges.pipe(
      debounceTime(700),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          editor: value,
          pageIndex: 0,
          masterPageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });


    this.idServerFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          id_server: value,
          pageIndex: 0,
          masterPageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.serverLocationFilter.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          server_location: value,
          pageIndex: 0,
          masterPageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.udpPortFilter.valueChanges.pipe(
      debounceTime(700),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          udp_port: value,
          pageIndex: 0,
          masterPageIndex: 0
        },
        queryParamsHandling: 'merge'
      });
    });

    this.monitoredChannelStatus.valueChanges
      .subscribe(value => {
        this.router.navigate(['monitoredchannels/'], {
          queryParams: {
            status: value,
            pageIndex: 0,
            masterPageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });

    this.protocolFilter.valueChanges
      .subscribe(value => {
        this.router.navigate(['monitoredchannels/'], {
          queryParams: {
            protocol: value,
            pageIndex: 0,
            masterPageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });


    this.showscheduleStatusFilter.valueChanges
      .subscribe(value => {
        this.router.navigate(['monitoredchannels/'], {
          queryParams: {
            showscheduleStatus: value,
            pageIndex: 0,
            masterPageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });


    this.sort.toArray()[0].sortChange
      .subscribe(() => {
        this.router.navigate(['monitoredchannels/'], {
          queryParams: {
            sortDirection: this.sort.toArray()[0].direction,
            sortActive: this.sort.toArray()[0].active,
            pageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });

    this.paginator.toArray()[0].page
      .subscribe(() => {
        this.router.navigate(['monitoredchannels/'], {
          queryParams: {
            pageIndex: this.paginator.toArray()[0].pageIndex,
            pageSize: this.paginator.toArray()[0].pageSize
          },
          queryParamsHandling: 'merge'
        });
      });

    this.sort.toArray()[1].sortChange
      .subscribe(() => {
        this.router.navigate(['monitoredchannels/'], {
          queryParams: {
            masterSortDirection: this.sort.toArray()[1].direction,
            masterSortActive: this.sort.toArray()[1].active,
            masterPageIndex: 0
          },
          queryParamsHandling: 'merge'
        });
      });

    this.paginator.toArray()[1].page
      .subscribe(() => {
        this.router.navigate(['monitoredchannels/'], {
          queryParams: {
            masterPageIndex: this.paginator.toArray()[1].pageIndex,
            masterPageSize: this.paginator.toArray()[1].pageSize
          },
          queryParamsHandling: 'merge'
        });
      });

    /* Dates */
    this.insertedFrom.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          pageIndex: 0,
          masterPageIndex: 0,
          insertedFrom: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.insertedTo.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged()
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          pageIndex: 0,
          masterPageIndex: 0,
          insertedTo: value ? value.format('YYYY-MM-DD') : null
        },
        queryParamsHandling: 'merge'
      });
    });

    this.statusGraphDateRange.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          statusGraphDateFrom: value.startDate,
          statusGraphDateTo: value.endDate
        },
        queryParamsHandling: 'merge'
      });
    });

    this.showStatusGraph.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (!(value)) {
        this.statusGraphDateRange.disable();
      } else {
        this.statusGraphDateRange.enable();
      }
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          statusGraphData: value
        },
        queryParamsHandling: 'merge'
      });
    });

    this.sourceGraphDateRange.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          sourceGraphDateFrom: value.startDate,
          sourceGraphDateTo: value.endDate
        },
        queryParamsHandling: 'merge'
      });
    });

    this.showSourceGraph.valueChanges.pipe(
      debounceTime(150),
      distinctUntilChanged(),
    ).subscribe(value => {
      if (!(value)) {
        this.sourceGraphDateRange.disable();
      } else {
        this.sourceGraphDateRange.enable();
      }
      this.router.navigate(['monitoredchannels/'], {
        queryParams: {
          sourceGraphData: value
        },
        queryParamsHandling: 'merge'
      });
    });

    this.tablesToShow.valueChanges
      .subscribe(value => {
        this.router.navigate(['monitoredchannels/'], {
          queryParams: {
            tables: value,
          },
          queryParamsHandling: 'merge'
        });
      });
  }

  loadMonitoredChannelsPage(): void {
    /**
     * Load a page of onpremise servers based on current parameters
     */

    /* Pagination */
    this.searchParams['offset'] = this.pageIndex * this.pageSize;
    this.searchParams['limit'] = this.pageSize;

    /* Sorting */
    this.searchParams['order'] = this.sortDirection;
    this.searchParams['sort'] = this.sortActive as 'channel' | 'editor' | 'id_server' | 'udp_port' | 'status' | 'inserted';


    /* Filters */
    this.searchParams['amchid'] = this.amchidFilter.value ? this.amchidFilter.value : null;
    this.searchParams['channel'] = this.channelFilter.value ? this.channelFilter.value : null;
    this.searchParams['editor'] = this.editorFilter.value ? this.editorFilter.value : null;
    this.searchParams['id_server'] = this.idServerFilter.value ? this.idServerFilter.value : null;
    this.searchParams['server_location'] = this.serverLocationFilter.value ? this.serverLocationFilter.value : null;
    this.searchParams['udp_port'] = this.udpPortFilter.value ? this.udpPortFilter.value : null;
    this.searchParams['protocol'] = this.protocolFilter.value ? this.protocolFilter.value : null;

    /* Dates */
    this.searchParams['insertedFrom'] = this.insertedFrom.value ? this.insertedFrom.value.format('YYYY-MM-DD') : null;
    this.searchParams['insertedTo'] = this.insertedTo.value ? this.insertedTo.value.format('YYYY-MM-DD') : null;

    /* Tasks */
    this.searchParams['status'] = this.monitoredChannelStatus.value;

    /* Status Graph */
    this.searchParams['statusGraphData'] = this.showStatusGraph.value;
    this.searchParams['statusGraphDateFrom'] = this.statusGraphDateRange.value.startDate.toISOString();
    this.searchParams['statusGraphDateTo'] = this.statusGraphDateRange.value.endDate.toISOString();

    /* Run */

    this.dataSource.loadMonitoredChannels(this.searchParams);



    /* Pagination */
    this.masterSearchParams['offset'] = this.masterPageIndex * this.masterPageSize;
    this.masterSearchParams['limit'] = this.masterPageSize;

    /* Sorting */
    this.masterSearchParams['order'] = this.masterSortDirection;
    this.masterSearchParams['sort'] = this.masterSortActive as 'channel' | 'editor' | 'id_server' | 'udp_port' | 'status' | 'inserted';


    /* Filters */
    this.masterSearchParams['amchid'] = this.amchidFilter.value ? this.amchidFilter.value : null;
    this.masterSearchParams['channel'] = this.channelFilter.value ? this.channelFilter.value : null;
    this.masterSearchParams['editor'] = this.editorFilter.value ? this.editorFilter.value : null;

    /* Dates */
    this.masterSearchParams['insertedFrom'] = this.insertedFrom.value ? this.insertedFrom.value.format('YYYY-MM-DD') : null;
    this.masterSearchParams['insertedTo'] = this.insertedTo.value ? this.insertedTo.value.format('YYYY-MM-DD') : null;

    this.masterSearchParams['showscheduleStatus'] = this.showscheduleStatusFilter.value;

    /* Status Graph */
    this.masterSearchParams['statusGraphData'] = this.showStatusGraph.value;
    this.masterSearchParams['statusGraphDateFrom'] = this.statusGraphDateRange.value.startDate.toISOString();
    this.masterSearchParams['statusGraphDateTo'] = this.statusGraphDateRange.value.endDate.toISOString();

    /* Source Graph */
    this.masterSearchParams['sourceGraphData'] = this.showSourceGraph.value;
    this.masterSearchParams['sourceGraphDateFrom'] = this.sourceGraphDateRange.value.startDate.toISOString();
    this.masterSearchParams['sourceGraphDateTo'] = this.sourceGraphDateRange.value.endDate.toISOString();

    /* Run */
    this.masterDataSource.loadMonitoredChannels(this.masterSearchParams);

  }


  startMonitoring(monitoredChannel: MonitoredChannel) {

    const dialogData: DialogData = {
      fingerprintVersion: 3,
      uploadOption: monitoredChannel.configuration['uploadOption']?.toString() ?? '7',
      segmentDuration: monitoredChannel.configuration['segmentDuration']?.toString() ?? '600',
      protocol: monitoredChannel.configuration['protocol'] ?? 'udp'
    }

    const dialogRef = this.dialog.open(MonitoredchannelConfigurationSettingsComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe(result => {
      if (!(result == null || result == '')) {
        const simpleMonitoredChannel: SimpleMonitoredChannel = {
          amchid: monitoredChannel.amchid,
          id_server: monitoredChannel.id_server,
          udp_port: monitoredChannel.udp_port,
          configuration: result,
          showschedule_status: monitoredChannel.showschedule_status
        };

        this.operate.startMonitoringChannel({ body: simpleMonitoredChannel })
          .pipe(finalize(() => {
            this.loadMonitoredChannelsPage();
          }))
          .subscribe(
            () => {
              this.snackBar.open("Monitoring requested for: " + monitoredChannel.amchid, '', {
                duration: 3000,
                panelClass: ['success-snack-bar']
              });
            },
            err => {
              const code = err.error.errorCode;
              const message = err.error.message;
              this.snackBar.open(`Error in requesting monitoring for: ${monitoredChannel.amchid}. Code: ${code}. Error: ${message}`, '', {
                duration: 5000,
                panelClass: ['alert-snack-bar']
              })
            });
      }
    });
  }

  stopMonitoring(amchid: string, id_server: string, udp_port: string) {
    const monitoredChannel: SimpleMonitoredChannel = {
      amchid: amchid,
      id_server: id_server,
      udp_port: udp_port
    };

    this.operate.stopMonitoringChannel({ body: monitoredChannel })
      .pipe(finalize(() => {
        this.loadMonitoredChannelsPage();
      }))
      .subscribe(
        () => {
          this.snackBar.open("Monitoring stop requested for: " + amchid, '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        },
        err => {
          const code = err.error.errorCode;
          const message = err.error.message;
          this.snackBar.open(`Error in requesting stop monitoring for: ${amchid}. Code: ${code}. Error: ${message}`, '', {
            duration: 5000,
            panelClass: ['alert-snack-bar']
          })
        });
  }

  goToContentList(channel: string, id_server: string, udp_port: string) {
    channel = channel.toUpperCase().replace(/\s*/g, "");
    this.router.navigate([`/channel/${channel}`], { queryParams: { id_server: id_server, udp_port: udp_port } });
  }

  deleteMonitoredChannel(status: string, amchid: string, id_server: string, udp_port: string) {
    if (status == 'running' || status == 'pending' || status == 'stopping') {
      this.snackBar.open(`Monitored channel can be deleted if are in ${status} status`, '', {
        duration: 5000,
        panelClass: ['alert-snack-bar']
      })
      return
    }

    this.deleteService.deleteMonitoredChannel({
      amchid: amchid, id_server: id_server,
      udp_port: udp_port
    }).subscribe(
      () => {
        this.snackBar.open("Monitored channel removed successfully ", '', {
          duration: 3000,
          panelClass: ['success-snack-bar']
        });

        this.loadMonitoredChannelsPage();
      },
      err => {
        const code = err.error.errorCode;
        const message = err.error.message;
        this.snackBar.open(`Error in deleting monitored channel: ${amchid}. Code: ${code}. Error: ${message}`, '', {
          duration: 5000,
          panelClass: ['alert-snack-bar']
        })
      });

  }

  dateTickFormatting(val: any): string {
    if (val instanceof Date) {
      return (<Date>val).toLocaleString('it-IT', { year: '2-digit', month: '2-digit', day: '2-digit' });
    }
  }

  parseNameValueSeries(data: any) {
    data.forEach((element: any) => {
      element.series.forEach((item: any) => {
        item.name = new Date(moment(item.name).toISOString());
        item.value = item.value == 0 ? '0' : item.value;
      });
    });

    return data
  }

  toggleShowSchedule(monitoredChannel: MonitoredChannel, event): void {
    let simpleMonitoredChannel: SimpleMonitoredChannel = {
      amchid: monitoredChannel.amchid,
      udp_port: monitoredChannel.udp_port,
      id_server: monitoredChannel.id_server
    }
    if (event.checked) {
      this.operate.setShowscheduleOn({ body: simpleMonitoredChannel }).subscribe(
        () => { console.log('Set show schedule on') },
        (err) => { console.log(err) }
      )
    } else {
      if (confirm("Are you sure to DEACTIVATE the showschedule procedure for this channel?")) {
        this.operate.setShowscheduleOff({ body: simpleMonitoredChannel }).subscribe(
          () => { this.loadMonitoredChannelsPage(); },
          (err) => { this.loadMonitoredChannelsPage(); }
        )
      } else {
        event.source.checked = true;
        this.loadMonitoredChannelsPage();
      }
    }
  }

  startMonitoringAll() {
    let filterParams = JSON.parse(JSON.stringify(this.searchParams))
    delete filterParams.status;
    delete filterParams.showscheduleStatus;
    filterParams['statusGraphData'] = false;
    filterParams['limit'] = -1
    filterParams['offset'] = 0

    this.retrieve.getMonitoredChannelsList({ ...filterParams }).subscribe(
      (monitoredChannels) => {
        let requests = [];
        let started = 0;
        monitoredChannels.forEach(
          (monitoredChannel) => {
            if (monitoredChannel.status == 'stopped' || monitoredChannel.status == 'error') {
              requests.push(
                this.operate.startMonitoringChannel({
                  body: {
                    amchid: monitoredChannel.amchid,
                    id_server: monitoredChannel.id_server,
                    udp_port: monitoredChannel.udp_port,
                    configuration: monitoredChannel.configuration
                  }
                }))
              started++;
            }
          }
        )
        if (requests.length > 0) {
          combineLatest(requests).subscribe(
            () => { this.loadMonitoredChannelsPage() },
            err => { }
          );
        }

        this.snackBar.open("Monitoring requested for: " + started + " channels", '', {
          duration: 3000,
          panelClass: ['success-snack-bar']
        });
      }
    )
  }

  stopMonitoringAll() {
    let filterParams = JSON.parse(JSON.stringify(this.searchParams))
    delete filterParams.status;
    delete filterParams.showscheduleStatus;
    filterParams['statusGraphData'] = false;
    filterParams['limit'] = -1
    filterParams['offset'] = 0

    this.retrieve.getMonitoredChannelsList({ ...filterParams }).subscribe(
      (monitoredChannels) => {
        let requests = [];
        let stopped = 0;
        monitoredChannels.forEach(
          (monitoredChannel) => {
            if (monitoredChannel.status == 'running') {
              requests.push(
                this.operate.stopMonitoringChannel({
                  body: {
                    amchid: monitoredChannel.amchid,
                    id_server: monitoredChannel.id_server,
                    udp_port: monitoredChannel.udp_port,
                  }
                }))
              stopped++;
            }
          }
        )
        if (requests.length > 0) {
          combineLatest(requests).subscribe(
            () => { this.loadMonitoredChannelsPage() },
            err => { }
          );
        }

        this.snackBar.open("Stop monitoring requested for: " + stopped + " channels", '', {
          duration: 3000,
          panelClass: ['success-snack-bar']
        });
      }
    )
  }

  showScheduleOnAll() {
    if (confirm("Are you sure to ACTIVATE the showschedule procedure for all monitored channels? ")) {
      let filterParams = JSON.parse(JSON.stringify(this.masterSearchParams))
      delete filterParams.status;
      delete filterParams.showscheduleStatus;
      filterParams['statusGraphData'] = false;
      filterParams['sourceGraphData'] = false;
      filterParams['limit'] = -1
      filterParams['offset'] = 0

      this.retrieve.getMastersMonitoredChannelsList({ ...filterParams }).subscribe(
        (monitoredChannels) => {
          let requests = [];
          let activated = 0;
          monitoredChannels.forEach(
            (monitoredChannel) => {
              if (monitoredChannel.showschedule_status == 'off') {
                requests.push(
                  this.operate.setShowscheduleOn({
                    body: {
                      amchid: monitoredChannel.amchid,
                      id_server: monitoredChannel.id_server,
                      udp_port: monitoredChannel.udp_port,
                    }
                  }))
                activated++;
              }
            }
          )
          if (requests.length > 0) {
            combineLatest(requests).subscribe(
              () => { this.loadMonitoredChannelsPage() },
              err => { }
            );
          }

          this.snackBar.open("Show schedule activated for " + activated + " monitored channels", '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        }
      )
    }
  }

  showScheduleOffAll() {
    if (confirm("Are you sure to DEACTIVATE the showschedule procedure for all monitored channels? ")) {
      let filterParams = JSON.parse(JSON.stringify(this.masterSearchParams))
      delete filterParams.status;
      delete filterParams.showscheduleStatus;
      filterParams['statusGraphData'] = false;
      filterParams['sourceGraphData'] = false;
      filterParams['limit'] = -1
      filterParams['offset'] = 0

      this.retrieve.getMastersMonitoredChannelsList({ ...filterParams }).subscribe(
        (monitoredChannels) => {
          let requests = [];
          let deactivated = 0;
          monitoredChannels.forEach(
            (monitoredChannel) => {
              if (monitoredChannel.showschedule_status == 'on') {
                requests.push(
                  this.operate.setShowscheduleOff({
                    body: {
                      amchid: monitoredChannel.amchid,
                      id_server: monitoredChannel.id_server,
                      udp_port: monitoredChannel.udp_port,
                    }
                  }))
                deactivated++;
              }
            }
          )
          if (requests.length > 0) {
            combineLatest(requests).subscribe(
              () => { this.loadMonitoredChannelsPage() },
              err => { }
            );
          }

          this.snackBar.open("Show schedule deactivated for " + deactivated + " monitored channels", '', {
            duration: 3000,
            panelClass: ['success-snack-bar']
          });
        }
      )
    }
  }

  download(fileType: string, masterChannels: boolean = false) {
    if (masterChannels) {
      this.retrieve.getNumMasterMonitoredChannels(this.masterSearchParams).subscribe(
        count => {
          if (confirm("Are you sure you wanto to export " + count + " monitored channels?")) {
            this.retrieve.getMastersMonitoredChannelsList({ ...this.masterSearchParams, limit: -1 }).subscribe(
              data => {
                const filteredData = data.map(({ amchid, id_server, showschedule_status, channel, editor }) => ({
                  amchid,
                  id_server,
                  showschedule_status,
                  channel,
                  editor,
                }));

                this.downloadFile(filteredData, fileType, (new Date()).toISOString())
              }
            )
          }
        }
      )
    } else {
      this.retrieve.getNumMonitoredChannels(this.searchParams).subscribe(
        count => {
          if (confirm("Are you sure you wanto to export " + count + " monitored channels?")) {
            this.retrieve.getMonitoredChannelsList({ ...this.searchParams, limit: -1 }).subscribe(
              data => {
                const filteredData = data.map(({ amchid, id_server, udp_port, status, channel, editor, location }) => ({
                  amchid,
                  id_server,
                  udp_port,
                  status,
                  channel,
                  editor,
                  location,
                }));

                this.downloadFile(filteredData, fileType, (new Date()).toISOString())
              }
            )
          }
        }
      )
    }
  }

  downloadFile(items, fileType: string, filename = 'data') {

    console.log(items)

    let blob
    if (fileType == 'csv') {

      // Replacer function to handle null value, number transformed with commas instead of dots and 
      // content title sanitized by removing semicolumn

      const replacer = function (key, value) {
        if (value === null) {
          return ''
        } else if (!isNaN(+value)) {
          return value.toString().replace('.', ',')
        } else if (typeof value === 'object') {
          if (value.hasOwnProperty("ContentTitle")) {
            value["ContentTitle"] = value["ContentTitle"].replaceAll(';', ' ')
          }
          return value
        } else {
          return value
        }
      };

      const header = Object.keys(items[0])
      const csv = [
        header.join(';'), // header row first
        ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(';'))
      ].join('\r\n')
      blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    }
    else if (fileType == 'json') {
      blob = new Blob([JSON.stringify(items, null, 2)], { type: 'application/json' });
    }

    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + "." + fileType);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
}
